import { useMutation } from '@tanstack/vue-query'
import { canGuard, canGuardWithPopup } from '@/Hooks/useGuard'
import { getLayout } from '@/data/layoutData'
import { useStickersStore } from '@/store/entity-system/useStickersStore'
import { useEditorStickersStore } from '@/store/editor/editorStickers'
import { useEditorCaptionsStore } from '@/store/editor/editorCaptions'
import { useEditorClipInfoStore } from '@/store/editor/editorClipInfo'
import { useEditorMainStore } from '@/store/editor/editorMain'
import { useFileUploads } from '@/components/Dialog/MultiUploadDialog/file-uploads/useFileUploads'
import { runWebcodecTest } from '@/webcodec-renderer/worker/webcodec-test'
import * as Sentry from '@sentry/vue'
import { type CreateRenderDto, RendererType } from '@/apis/streamladder-api/model'
import { postApiRenders } from '@/apis/streamladder-api/renders/renders'
import { getSegmentsFromStores } from '@/components/Clip2TikTok/renderingHelpers'
import logging from '@/logging'
import {useFeatureFlagEnabled} from "@/Hooks/useFeatureFlagEnabled";
import { requestUserSignInAsync } from '@/authentication/supabase'

export const useRenderRequest = () => {
  const stickersStore = useStickersStore()
  const editorStickersStore = useEditorStickersStore()
  const editorCaptionsStore = useEditorCaptionsStore()
  const editorMainStore = useEditorMainStore()

  const validateUser = async () => {
    const userIsAuthenticated = await requestUserSignInAsync('Please login to render your video')
    if (!userIsAuthenticated)
      return {
        type: 'no-user',
        message: 'User is not authenticated',
      } as const

    const hasStickers = editorStickersStore.hasStickers
    if (hasStickers && !canGuardWithPopup('stickers')) {
      return {
        type: 'no-access',
        guard: 'stickers',
        message: 'User does not have enough access to stickers',
      } as const
    }

    const hasCaptions = editorCaptionsStore.hasCaptions
    if (hasCaptions && !canGuardWithPopup('captions')) {
      return {
        type: 'no-access',
        guard: 'captions',
        message: 'User does not have enough access to captions',
      } as const
    }

    const hasPremiumLayout = getLayout(editorMainStore.layoutName)?.premium || false
    if (hasPremiumLayout && !canGuardWithPopup('layouts')) {
      return {
        type: 'no-access',
        guard: 'layouts',
        message: 'User does not have enough access to layouts',
      } as const
    }

    const hasGiphyStickers = stickersStore.entities.some((sticker) => sticker.type === 'giphy')
    if (hasGiphyStickers && !canGuardWithPopup('giphy-stickers')) {
      return {
        type: 'no-access',
        guard: 'giphy-stickers',
        message: 'User does not have enough access to Giphy stickers',
      } as const
    }
  }

  const uploadFile = async () => {
    const fileUploads = useFileUploads()

    const clipInfo = useEditorClipInfoStore()

    const clipUpload = fileUploads.selectById(clipInfo.id)

    if (clipUpload?.value?.suspense) {
      console.log('wait for file upload to finish..')
      await clipUpload.value.suspense()
    }

    if (clipUpload?.value) {
      if (clipUpload.value.urls?.videoSignedUploadRequest?.resultUrl) {
        clipInfo.mp4Url = clipUpload.value.urls.videoSignedUploadRequest.resultUrl
        return
      } else {
        if (clipUpload.value.error) {
          return {
            type: 'error',
            message: clipUpload.value.error,
          } as const
        }
      }
    }
  }

  const getRenderData = () => {
    const stickersStore = useStickersStore()
    const editorCaptionsStore = useEditorCaptionsStore()
    const editorClipInfoStore = useEditorClipInfoStore()
    const editorMainStore = useEditorMainStore()
    const stickerData = stickersStore.entities

    const segments = getSegmentsFromStores()

    for (const segment of segments) {
      segment.cropData?.sort((a, b) => (a.zIndex ?? 0) - (b.zIndex ?? 0))
    }

    return {
      contentUrl: editorClipInfoStore.mp4Url,
      title: editorClipInfoStore.title ?? 'Title',
      segments: segments,
      outputFPS: editorMainStore.outputWidth === 1080 ? 60 : 30,
      outputResolution: editorMainStore.outputWidth === 1080 ? 1080 : 720,
      overlay: {
        Stickers: stickerData,
        Captions: editorCaptionsStore.captions,
        CaptionsWrapper: editorCaptionsStore.captionsWrapper,
        DurationMs: editorMainStore.videoDuration,
        captionsObject: {
          CaptionStyleSettings: editorCaptionsStore.captionStyleSettings,
          BaseOptions: editorCaptionsStore.baseOptions,
          captionPreset: editorCaptionsStore.captionPreset,
        },
      },
    } as CreateRenderDto
  }

  const postRender = async (data: CreateRenderDto) => {
    try {
      const res = await postApiRenders(data)
      if (res == null)
        return {
          type: 'error',
          message: 'No response',
        } as const

      if (!res.value?.id) {
        Sentry.captureException(new Error('No taskId'))
        return {
          type: 'error',
          message: 'No taskId',
        } as const
      }
      return {
        type: 'rendering',
        task: res.value,
        renderType: data.rendererType,
      } as const
    } catch (e) {
      Sentry.captureException(e)
      return {
        type: 'error',
        message: 'Server error',
        error: e,
      } as const
    }
  }

  const _renderVideo = async () => {
    // validate user rights
    const userValidation = await validateUser()
    if (userValidation) return userValidation

    const { supported: webcodecSupported } = await runWebcodecTest()

    console.log('webcodecSupported', webcodecSupported)

    const canServerSideRender = canGuard('server-side-rendering')
    const webcodecServerRendererfeatureFlag = useFeatureFlagEnabled('new-editor-v2').value

    let renderLocation = 'client' as 'client' | 'server'
    if ((!webcodecSupported && canServerSideRender) || webcodecServerRendererfeatureFlag) renderLocation = 'server'
    if (!webcodecSupported && !canServerSideRender) {
      return {
        type: 'no-browser-support',
        message: 'Rendering on the client using Webcodecs is not supported on this browser',
      } as const
    }

    console.log('renderLocation', renderLocation)

    // upload file if user can server side render
    if (canServerSideRender) {
      const fileUploadError = await uploadFile()
      if (fileUploadError) return fileUploadError
    }

    const webcodecRenderData = getRenderData()
    webcodecRenderData.rendererType =
      renderLocation === 'server' ? RendererType.serverSideWebCodecRenderer : RendererType.webCodecRenderer

    return postRender(webcodecRenderData)
  }

  const trackClipCreation = (message: Awaited<ReturnType<typeof mutateAsync>>) => {
    const eventProperties = {}
    if (['no-user', 'no-access', 'no-browser-support'].includes(message.type)) {
      logging.trackEvent('Clip Creation Rejected ', {
        ...eventProperties,
        message: message.message,
      })
      return
    }

    if (message.type === 'rendering') {
      logging.trackEvent('Clip Created', {
        ...eventProperties,
        task: message.task,
      })
      return
    }
    if (message.type === 'error') {
      logging.trackEvent('Clip Creation Error', {
        ...eventProperties,
        message: message.message,
      })
      return
    }
  }

  const { error, mutateAsync, isError, isIdle, isPaused, isPending, isSuccess } = useMutation({
    mutationFn: _renderVideo,
  })

  const renderVideo = async () => {
    try {
      const result = await mutateAsync()
      trackClipCreation(result)
      console.log('result', result)
      return result
    } catch (e) {
      Sentry.captureException(e)
      return {
        type: 'error',
        message: 'Server error',
        error: e,
      } as const
    }
  }

  return {
    error,
    renderVideo,
    isError,
    isIdle,
    isPaused,
    isPending,
    isSuccess,
  }
}
