<script setup lang="ts">
import stickerLibrary from '@/components/Stickers/stickerLibrary/stickerLibrary'
import { computed, ref, watch, toRaw } from 'vue'
import { useElementSize } from '@vueuse/core'
import type TextSticker from '@/components/Stickers/TextSticker.vue'
import textLibrary from '@/components/Stickers/textLibrary'
import { omit, debounce } from 'lodash-es'
import type { ClassValue } from 'clsx'
import GiphyElement from '@/components/Stickers/GiphyElement.vue'
import { useMovableContext } from '@/modules/SLMovable/useMovableContext'
import { cn } from '@/lib/utils'
import type { Sticker } from '@/areas/editor/@type/Project'
import { useWorkspaceBoundingContext } from '@/areas/editor/context/workspaceSize'
import { useStickersStore } from '@/areas/editor/store/useStickersStore'

const props = defineProps<{
  id?: string, class?: ClassValue,
  renderWidth: number, renderHeight: number,
  focus?: boolean
}>()

const emit = defineEmits<{
  (event: 'updateContent', text: string): void
  (event: 'ready'): void
}>()

const sticker = defineModel<Sticker>({ required: true })

const stickerMetaData = computed(() => {
  if (sticker.value.type === 'giphy') {
    return { component: toRaw(GiphyElement) }
  } else {
    const library = [...textLibrary, ...stickerLibrary]
    return library.find(({ key }) => key === sticker.value.key);
  }
})

const stickerBinding = computed(() => {
  const omitNaturalSize = sticker.value.key === 'custom-sticker' ? ['naturalWidth', 'naturalHeight'] : []
  return {
    ...omit(sticker.value, ['key', 'area', 'id', 'z', 'type', 'textContent', 'scale', ...omitNaturalSize]),
    ...omit(stickerMetaData.value, ['component', 'key', 'colors', 'createdAt', 'tags', 'title']),
  }
})

const naturalElement = ref<HTMLDivElement>()
const { width: naturalWidth, height: naturalHeight } = useElementSize(naturalElement)

const absoluteScale = ref(1)
watch([() => props.renderWidth, () => sticker.value.area.width], () => {
  absoluteScale.value = (sticker.value.area.width * props.renderWidth / naturalWidth.value)
})

watch([naturalWidth, naturalHeight], ([naturalWidth, naturalHeight]) => {
  if (sticker.value.type !== 'giphy') {
    sticker.value.naturalWidth = naturalWidth
    sticker.value.naturalHeight = naturalHeight
  }
}, { immediate: true })

const componentRef = ref<InstanceType<TextSticker>>()
defineExpose({
  setEditMode() {
    componentRef.value?.setEditMode?.()
  }
})

function updateStore() {
  setTimeout(() => {
    sticker.value.area.width = naturalWidth.value * absoluteScale.value / props.renderWidth
    sticker.value.area.height = naturalHeight.value * absoluteScale.value / props.renderHeight
  }, 0)
}

const debouncedUpdateStore = debounce(updateStore, 100)
const hide = ref(true)
function onLoad() {
  setTimeout(() => {
    if (sticker.value.scale) {
      absoluteScale.value = sticker.value.scale * props.renderWidth
      updateStore()
      delete sticker.value.scale
    } else {
      absoluteScale.value = (sticker.value.area.width * props.renderWidth / naturalWidth.value)
    }

    hide.value = false
    emit('ready')
  }, 0)
}

function contain() {

  if (!naturalElement.value) return

  const width = naturalElement.value.clientWidth * absoluteScale.value
  const height = naturalElement.value.clientHeight * absoluteScale.value

  sticker.value.area.width = width / props.renderWidth;
  sticker.value.area.height = height / props.renderHeight;
}

function updateContent(text: string) {
  
  if ('textContent' in sticker.value) {

    const allTextHasBeenCleared = text.length === 1 && text.charCodeAt(0) === 10; // `10` is a line feed.
    if (allTextHasBeenCleared) {
      const stickersStore = useStickersStore();
      stickersStore.removeById(sticker.value.id);
      return;
    }

    sticker.value.editing = false
    contain()
    sticker.value.textContent = text
  }

  debouncedUpdateStore()
  emit('updateContent', text)
}

const input = ref(sticker.value.textContent)
const element = ref<HTMLDivElement>()

const padding = computed(() => sticker.value.type === 'custom' ? 0 : 30)

const movableContext = useMovableContext()!
const movableX = computed(() => movableContext.x.value ?? 0)
const movableY = computed(() => movableContext.y.value ?? 0)
const movableWidth = computed(() => movableContext.width.value ?? 0)
const movableHeight = computed(() => movableContext.height.value ?? 0)

const { x: workspaceX, y: workspaceY, width: workspaceWidth, height: workspaceHeight, scrollX, scrollY } = useWorkspaceBoundingContext()!
watch([workspaceX, workspaceY, workspaceWidth, workspaceHeight, scrollX, scrollY], () => {
  movableContext.resize()
})

const updateRect = (content: string) => {
  input.value = content
}
</script>

<template>
  <template 
    v-if="stickerMetaData?.component"
  >
    <div ref="element" :id="props.id"
      :style="{
        transform: `scale(${absoluteScale})`,
        width: naturalWidth + (2 * padding) + 'px',
        padding: padding + 'px',
        margin: `-${padding * absoluteScale}px 0 0 -${padding * absoluteScale}px`,
        display: hide ? 'none' : undefined
      }"
      :class="cn('light origin-top-left', props.class)"
    >
      <component ref="componentRef" :editable="true"
        class="whitespace-pre-wrap [&_*]:!whitespace-pre-wrap"
        @onAssetLoaded="updateContent"
        @stickerLoaded="onLoad"
        :is="stickerMetaData.component"
        :htmlContent="'textContent' in sticker ? sticker.textContent : null"
        @updateRect="updateRect"
        @updateContent="updateContent"
        v-bind="stickerBinding"
      />
    </div>
    <div ref="naturalElement" class="fixed top-0 left-0 opacity-0 invisible pointer-events-none whitespace-pre-wrap [&_*]:!whitespace-pre-wrap">
      <component class="relative" :is="stickerMetaData.component" :htmlContent="input" v-bind="stickerBinding" :editable="false" />
    </div>
    <Teleport to="#workspace">
      <div
        class="fixed grayscale transition-opacity z-[1] origin-top-left whitespace-pre-wrap [&_*]:!whitespace-pre-wrap"
        :class="focus ? 'opacity-25' : 'opacity-0'"
        :style="{
          left: movableX + sticker.area.x * movableWidth + 'px',
          top: movableY + sticker.area.y * movableHeight + 'px',
          transform: `scale(${absoluteScale})`,
          width: naturalWidth + 'px',
          height: naturalHeight + 'px',
          display: hide ? 'none' : undefined
        }"
      >
        <component :is="stickerMetaData.component" :htmlContent="input" v-bind="stickerBinding" :editable="false" />
      </div>
    </Teleport>
  </template>
</template>

<style scoped lang="scss">

</style>
