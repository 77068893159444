<template>
  <div class="flex w-full flex-col items-start gap-4 lg:flex-row">
    <div class="panel w-full p-12 lg:w-2/3">
      <div class="mb-0 flex flex-col">
        <h2 class="text-3xl font-bold">Review your changes</h2>
        <p class="mb-6 font-light">Take one last look: you can still revisit your plan.</p>

        <ol class="flex w-full flex-col space-y-4">
          <Feature type="lost" v-for="(feature, i) in allFeaturesByTier.silver" :key="i">
            {{ feature }}
          </Feature>
          <Feature type="lost" v-if="this.plan === 'gold'">
            You'll also lose all
            <a
              :href="$router.resolve({ name: 'Upgrade', hash: '#compare' }).href"
              target="_blank"
              class="link text-indigo-500"
            >
              Gold features
            </a>
          </Feature>
        </ol>
      </div>
    </div>

    <!-- dialog section -->
    <div v-if="!isLoading && !isLoadingPrices" class="panel flex w-full flex-col gap-4 p-8 lg:w-1/3">
      <div class="inline-flex flex-col items-start justify-start gap-4">
        <h4 class="m-0 p-0 text-2xl font-bold">Your new plan</h4>
        <ol class="flex flex-col items-start justify-start gap-1">
          <Feature type="gained" v-for="(feature, i) in allFeaturesByTier.free" :key="i">
            {{ feature }}
          </Feature>
        </ol>
      </div>

      <div class="h-px bg-zinc-200" />

      <div class="flex items-start justify-end" v-if="nextCharge">
        <div class="flex-auto">
          <p class="font-bold">Your next charge:</p>
          <p class="font-light">(on {{ formatDate(nextCharge.date) }})</p>
        </div>
        <div class="font-light">{{ nextCharge.displayPrice }}</div>
      </div>

      <div class="flex flex-col gap-2">
        <button
          :class="{ loading: isProcessing }"
          @click="cancelSubscription"
          class="btn-primary btn h-auto flex-col gap-0.5 rounded py-3"
          role="button"
        >
          <span>Cancel your plan</span>
          <span class="text-xs font-thin transition-colors"> End your subscription </span>
        </button>

        <router-link
          :to="{ name: 'Home' }"
          class="group btn-outline btn-primary btn h-auto flex-col gap-0.5 rounded py-3"
          role="button"
        >
          <span>Keep your subscription</span>
        </router-link>
      </div>
    </div>
    <!-- end dialog section -->

    <error-dialog
      ref="errorDialog"
      :action="
        () => {
          $router.push({ name: 'Home' })
        }
      "
      button-text="Ok, I understand"
      title="Failed to cancel your subscription"
    >
      <p>Please contact us by opening a ticket in our Discord.</p>
      <a :href="settings.discordInviteUrl" class="btn-primary btn-lg btn mx-auto mt-3 gap-2" target="_blank">
        <discord-logo class="w-8" fill="#FFF" />
        Join Discord
      </a>
    </error-dialog>

    <!-- Discount dialog -->
    <tw-base-dialog
      :modelValue="showDiscountModal"
      :background="'bg-gradient'"
      :closable="true"
      styles="max-w-[1000px] my-[100px] mx-0.5"
      @update:modelValue="showDiscountModal = false"
    >
      <template v-slot:header>
        <div />
      </template>
      <div class="items-center gap-16 p-12 text-white lg:grid lg:grid-cols-12">
        <div class="col-span-12 flex flex-col items-start gap-2 lg:col-span-7">
          <h3 class="p-0 text-4xl font-semibold text-white">Don’t go! Get 50% off on your next month</h3>
          <p class="font-thin">
            Keep your subscription and get a 50% discount on your subscription fee for the next month.
          </p>
          <p class="font-thin">Plus, think of all the amazing features that you’ll be missing out on:</p>

          <ol class="my-4 flex flex-col gap-4">
            <Feature type="gained"> AI-powered captions </Feature>
            <Feature type="gained"> Export in 1080p / 60fps </Feature>
            <Feature type="gained"> Adding stickers and text </Feature>
            <Feature type="gained"> And many more </Feature>
          </ol>

          <button
            class="group btn w-full gap-2 self-center border-white bg-white text-[#4D287F] hover:bg-white/75 md:max-w-[80%]"
            @click="applyDiscountAndContinue"
          >
            Stay and keep access
            <svg
              class="h-5 w-5 transition group-hover:translate-x-2"
              fill="currentColor"
              height="24"
              viewBox="0 0 24 24"
              width="24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.43 18.8191C14.24 18.8191 14.05 18.7491 13.9 18.5991C13.61 18.3091 13.61 17.8291 13.9 17.5391L19.44 11.9991L13.9 6.45914C13.61 6.16914 13.61 5.68914 13.9 5.39914C14.19 5.10914 14.67 5.10914 14.96 5.39914L21.03 11.4691C21.32 11.7591 21.32 12.2391 21.03 12.5291L14.96 18.5991C14.81 18.7491 14.62 18.8191 14.43 18.8191Z"
              />
              <path
                d="M20.33 12.75H3.5C3.09 12.75 2.75 12.41 2.75 12C2.75 11.59 3.09 11.25 3.5 11.25H20.33C20.74 11.25 21.08 11.59 21.08 12C21.08 12.41 20.74 12.75 20.33 12.75Z"
              />
            </svg>
          </button>
          <button
            class="group btn w-full gap-2 self-center border-current bg-transparent text-white hover:bg-white/75 hover:text-[#4D287F] md:max-w-[80%]"
            @click="
              () => {
                showDiscountModal = false
              }
            "
          >
            No, I still want to cancel
            <svg
              class="h-5 w-5 transition-transform group-hover:translate-x-2"
              fill="currentColor"
              height="24"
              viewBox="0 0 24 24"
              width="24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.43 18.8191C14.24 18.8191 14.05 18.7491 13.9 18.5991C13.61 18.3091 13.61 17.8291 13.9 17.5391L19.44 11.9991L13.9 6.45914C13.61 6.16914 13.61 5.68914 13.9 5.39914C14.19 5.10914 14.67 5.10914 14.96 5.39914L21.03 11.4691C21.32 11.7591 21.32 12.2391 21.03 12.5291L14.96 18.5991C14.81 18.7491 14.62 18.8191 14.43 18.8191Z"
              />
              <path
                d="M20.33 12.75H3.5C3.09 12.75 2.75 12.41 2.75 12C2.75 11.59 3.09 11.25 3.5 11.25H20.33C20.74 11.25 21.08 11.59 21.08 12C21.08 12.41 20.74 12.75 20.33 12.75Z"
              />
            </svg>
          </button>

          <div class="alert alert-error mt-4" v-if="discountError">
            <CrossIcon class="h-12 w-12 fill-error" />
            <p class="!inline text-error">
              Whoops! Something went wrong when applying your discount. Please reach out to us on our
              <a :href="settings.discordInviteUrl" target="_blank" class="link">Discord</a> so we can help you save some
              money!
            </p>
          </div>
        </div>
        <div class="hidden lg:col-span-5 lg:block">
          <img alt="Megaphone icon" src="/images/announcement.png" class="w-full object-contain" />
        </div>
      </div>
    </tw-base-dialog>
  </div>
</template>
<script>
import { accountsAxios } from '@/services/axios'
import settings from '@/data/settings'
import { useUserInfoStore } from '@/store/user/userInfo'
import { useUserSubscriptionStore } from '@/store/user/userSubscription'
import plans from '@/data/plans'
import { mapState } from 'pinia/dist/pinia'
import TwBaseDialog from '@/components/Dialog/TwBaseDialog.vue'
import ErrorDialog from '@/components/Dialog/ErrorDialog.vue'
import CrossIcon from '@/components/Icons/CrossIcon.vue'
import * as Sentry from '@sentry/browser'

import { useProductsStore, getDisplayPrice, DEVELOPER_GOLD_PLAN } from '@/store/products'
import DiscordLogo from '@/components/Icons/DiscordLogo.vue'
import Feature from '@/components-v2/lists/Feature.vue'
import { allFeaturesByTier } from '@/data/allFeaturesByTier'
import {
  postApiSubscriptionDiscounts,
  putApiSubscriptionIdCancel, putApiSubscriptionIdPause
} from '@/apis/streamladder-accounts/subscription/subscription'
import { productIds } from '@/data/_productIds'
import EventBus from '@/eventBus'
import mainEvents from '@/events/mainEvents'

export default {
  components: { Feature, ErrorDialog, DiscordLogo, TwBaseDialog, CrossIcon },
  data() {
    return {
      settings,
      plans,
      allFeaturesByTier,
      isProcessing: false,
      showDiscountModal: false,
      discountError: false,
    }
  },
  computed: {
    ...mapState(useUserInfoStore, ['plan', 'hasSubscription']),
    ...mapState(useUserSubscriptionStore, ['isLoading', 'subscription', 'isPaddleSubscription']),
    ...mapState(useProductsStore, ['products', 'isLoadingPrices']),
    currentSubscriptionProduct() {
      if (this.isLoadingPrices || !this.subscription.subscriptionPlanId) {
        return null
      }
      return this.products.find((x) => x.productId === this.subscription.subscriptionPlanId)
    },
    freeDisplayPrice() {
      // showed in the users currency
      return getDisplayPrice(0, this.currentSubscriptionProduct.currency)
    },
    newSubscriptionProduct() {
      if (!this.currentSubscriptionProduct) {
        return null
      }
      const plan = this.plans[this.plan].plans.find((p) => p.interval === this.currentSubscriptionProduct.interval)
      return this.products.find((x) => x.productId === plan?.paddle)
    },
    nextCharge() {
      if (!this.subscription?.nextPayment?.date || !this.newSubscriptionProduct) {
        return null
      }

      const date = this.formatDate(this.subscription?.nextPayment?.date)
      const displayPrice = getDisplayPrice(this.newSubscriptionProduct, this.newSubscriptionProduct.currency)
      return { displayPrice, date }
    },
  },
  async mounted() {
    // silver + gold monthly only
    const validPlans = [productIds.SILVER.MONTHLY, productIds.GOLD.MONTHLY, DEVELOPER_GOLD_PLAN]

    // should discount be offered?
    if (this.reason !== 'stopped_streaming' && validPlans.includes(parseInt(this.subscription.subscriptionPlanId))) {
      await this.$trackEvent('Subscription Cancel Discount Offered', { plan: this.plan })
      this.discountError = false
      this.showDiscountModal = true
    }
  },
  methods: {
    async applyDiscountAndContinue() {
      try {
        await postApiSubscriptionDiscounts()
        await this.$trackEvent('Subscription Cancel Discount Accepted', { plan: this.plan })
        await this.$router.push({ name: 'SubscriptionUpdated', query: { to: 'discount' } })
      } catch (e) {
        Sentry.captureException(e)
        this.discountError = true
      }
    },
    async cancelSubscription() {
      this.isProcessing = true

      try {
        if (this.subscription.type === 'paddle') {

          // For Paddle we need to pause the subscription, not cancel it
          const response = await putApiSubscriptionIdPause(this.subscription.id);

          if (!response.isSuccess) {
            console.error('Error while pausing subscription', JSON.stringify(response.error));
            Sentry.captureException(new Error('Error while pausing subscription'));
            EventBus.$emit(mainEvents.ERROR, 'Error while pausing subscription. Please contact support.');
          }

        } else if (this.subscription.type === 'paypal') {

          const response = await putApiSubscriptionIdCancel(this.subscription.id, {
            reason: '',
          });

          if (!response.isSuccess) {
            console.error('Error while canceling subscription', JSON.stringify(response.error));
            Sentry.captureException(new Error('Error while canceling subscription'));
            EventBus.$emit(mainEvents.ERROR, 'Error while canceling subscription. Please contact support.');
          }

          this.isProcessing = false
          const reason = this.$route.query.reason || 'unknown'
          this.$trackEvent('Subscription Cancelled Frontend', { Reason: reason, plan: this.plan })
        } else {
          console.error('Unknown subscription type', this.subscription.type)
          Sentry.captureException(new Error('Unknown subscription type'))
          EventBus.$emit(mainEvents.ERROR, 'Unknown subscription type. Please contact support.')
        }
      } catch (e) {
        this.isProcessing = false
        Sentry.captureException(e)
        this.$refs.errorDialog.open()
        return
      }

      this.$router.push({ name: 'SubscriptionUpdated', query: { to: 'cancelled' } })
    },
    formatDate(dateStr) {
      if (dateStr == null) return ''

      const date = new Date(dateStr)
      return Intl.DateTimeFormat('en-US', { dateStyle: 'long' }).format(date)
    },
  },
}
</script>
