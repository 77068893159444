<script lang="ts" setup>
import { useUserInfoStore } from '@/store/user/userInfo'
import ClipForm from '@/areas/dashboard/components/ClipForm.vue'
import { useTimeBasedGreetings } from '@/areas/dashboard/hooks/useTimeBasedGreetings'
import { useHead } from '@unhead/vue'
import DashboardTwitchClipsGrid from '@/areas/dashboard/components/DashboardTwitchClipsGrid.vue'
import { useFeatureFlagEnabled, useElgatoContestBannerV2 } from '@/Hooks/useFeatureFlagEnabled'
import { posthog } from 'posthog-js'
import { useRouter } from 'vue-router'
import { useResetEditor } from '@/Hooks/useResetEditor'
import { useStartup } from '@/areas/editor/startup'
import type { Clip } from '@/Hooks/useTwitchClips'

const greeting = useTimeBasedGreetings()
const userInfo = useUserInfoStore()

useHead({
  title: 'Clip Editor',
  meta: [
    {
      name: 'description',
      content: 'Convert your clips to viral TikToks, Instagram Reels and YouTube Shorts',
    },
  ],
})

const router = useRouter()
const resetEditor = useResetEditor()
const { startupFromTwitchClipByClipId } = useStartup()
const useNewEditor = useFeatureFlagEnabled('new-editor-v2')
async function onClipSelected(clip: Clip) {
  resetEditor()
  if (useNewEditor.value) {
    await startupFromTwitchClipByClipId(clip.id)
  } else {
    await router.push({ name: 'editor', params: { clipId: clip.id, source: 'twitch-clip' } })
  }
}

const showElgatoContest = useFeatureFlagEnabled('elgato-contest');
const { banner, variant } = useElgatoContestBannerV2();

const openContestInNewTab = () => {
  posthog.capture('Elgato Banner Clicked', { location: 'Dashboard Clip Editor Page', variant: variant.value });
  window.open('https://streamladder.com/contest2024/?utm_source=sl-website&utm_campaign=contest2024&utm_content=cta-bottom', '_blank');
};
</script>

<template>
  <main>
    <header
      class="flex w-full flex-col items-center gap-8 rounded-t-xl bg-gradient-to-br from-purple-800 to-indigo-500 dark:from-purple-200 dark:to-indigo-400  p-4 sm:p-8 lg:p-24"
    >
      <div class="flex flex-col items-center gap-1">
        <h1 v-if="userInfo.isAuthenticated" class="text-center text-white text-3xl lg:text-5xl">
          {{ greeting }},
          <span class="font-bold">{{ userInfo.userName }}!</span>
        </h1>
        <h1 v-else class="text-center text-white text-3xl  lg:text-5xl">
          {{ greeting }}!
        </h1>
        <p class="font-light text-white ">Start by selecting a clip</p>
      </div>
      <ClipForm class="mx-auto w-full max-w-[720px] self-stretch" />
    </header>

    <section class="flex flex-col gap-4 px-6 py-8 lg:p-12">
      <img
        v-if="showElgatoContest"
        @click="openContestInNewTab"
        class="w-full h-auto rounded-xl cursor-pointer hover:scale-[101%] transition-transform"
        :src="banner"
        alt="Elgato Contest Promo Banner"
      />

      <DashboardTwitchClipsGrid @selected="onClipSelected" />
    </section>
  </main>
</template>

<style lang="scss" scoped></style>
