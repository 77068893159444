<script setup lang="ts">
import type { Tier } from '@/data/plans'
import LightSwitch from '@/components-v2/data-input/boolean/LightSwitch.vue'
import { useSubscriptionIntervals } from '@/Hooks/useSubscriptionIntervals'
import unwrap from '@/helpers/unwrap'
import plans from '@/data/plans'
import { usePlanUpgrade } from '@/Hooks/usePlanUpgrade'
import UpgradeDialogCardsTiersCard from '@/components/Dialog/Gold/UpgradeDialogCardsTiersCard.vue'

withDefaults(
  defineProps<{
    tiers?: Tier[]
    variant: 'opaque' | 'transparent'
    reason: string
  }>(),
  {
    tiers: unwrap.values(plans),
  }
)

const { interval, intervals } = useSubscriptionIntervals()
const { isLoading } = usePlanUpgrade()
</script>

<template>
  <div class="flex flex-col items-center gap-2">
    <div
      class="flex items-center justify-center gap-1 rounded-full px-3 py-1.5"
      :class="{ 'bg-emerald-600': variant === 'transparent' }"
    >
      <span class="relative text-sm font-bold leading-tight text-slate-50">
        {{ interval === 'month' ? 'Save 20% on a yearly plan!' : 'You are saving 20% 👌' }}
        <svg
          class="absolute left-full top-1/2 -translate-x-2 rotate-12 transform"
          :class="variant === 'transparent' ? 'stroke-emerald-600' : 'stroke-white'"
          stroke="#059669"
          xmlns="http://www.w3.org/2000/svg"
          width="48"
          height="53"
          viewBox="0 0 48 53"
          fill="none"
        >
          <path
            d="M12.3248 4.48809C12.3248 4.48809 38.1841 1.99083 44.2109 13.438C52.2898 28.7829 10.7622 42.6534 10.7622 42.6534M10.7622 42.6534L21.5849 47.8718M10.7622 42.6534L15.9814 31.8248"
            stroke-width="2"
          />
        </svg>
      </span>
    </div>

    <LightSwitch
      class="bg-white text-sm"
      :options="Object.values(intervals)"
      :class="isLoading && 'pointer-events-none'"
      background="bg-gradient"
      v-model:value="interval"
      labelClasses="text-white"
    />
  </div>

  <ol class="mt-4 flex w-full flex-col gap-6 md:grid" :style="{ gridTemplateColumns: `repeat(${tiers.length}, 1fr)` }">
    <li v-for="tier in [...tiers].reverse()" v-bind:key="tier.key">
      <UpgradeDialogCardsTiersCard :tier="tier" :reason="reason" />
    </li>
  </ol>
</template>
