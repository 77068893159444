import EventBus from '../eventBus'
import authEvents from '../events/authEvents'
import { tiers } from '@/enums/tiers'
import upgradeEvents from '../events/upgradeEvents'
import { useUserInfoStore } from '@/store/user/userInfo'
import { useSaleStore } from '@/store/sale'
import { getInterval, getTier, useProductsStore } from '@/store/products'
import { markRaw } from 'vue'
import { useCreateSubscription } from '@/Hooks/useCreateSubscription'
import { getApiSubscriptionBillingInfo, getApiSubscriptionGeneratePaymentLinkV2, postApiSubscriptionChangePlan } from '@/apis/streamladder-accounts/subscription/subscription'
import * as Sentry from '@sentry/vue'

export default {
  methods: {
    upgradeable(tier) {
      const userInfoStore = useUserInfoStore()
      return tier > userInfoStore.tier
    },
    downgradable(tier) {
      const userInfoStore = useUserInfoStore()
      return tier < userInfoStore.tier
    },
    gold() {
      const productsStore = useProductsStore()
      return productsStore.productData.gold.tier
    },
    silver() {
      const productsStore = useProductsStore()
      return productsStore.productData.silver.tier
    },
    getBadgeComponent(key) {
      const productsStore = useProductsStore()

      if (typeof key === 'number') {
        key = this.tierNumberToDisplayString(key).toLowerCase()
      }

      if (!productsStore.productData[key]) {
        console.error(`${key} is not an available plan`)
        return null
      }
      return markRaw(productsStore.productData[key].badge)
    },
    tierNumberToDisplayString(num) {
      return tiers.toString(num)
    },
    async attemptCheckout(productId, discountCode, reason) {

      const userInfoStore = useUserInfoStore()

      if (userInfoStore.userId === undefined) {
        EventBus.$emit(authEvents.OPEN_LOGIN_DIALOG);
        return;
      }

      const meta = {
        interval: getInterval(productId),
        tier: getTier(productId),
      }

      // If a user has no subscription yet, create one through paddle.
      if (userInfoStore.tier === 0) {

        let data = { product: productId, ...meta }

        // If the checkout happens during Black Friday and the product is a year plan, add the Black Friday coupon
        const saleStore = useSaleStore()
        if (saleStore.isBlackFriday && [794144, 777416].includes(productId)) {
          data = { ...data, coupon: 'BLACKFRIDAY22' };
        }

        if (discountCode) {
          data = { ...data, coupon: discountCode };
        }

        await this.createSubscription(data, reason);
        return;
      }

      // Below is the flow for upgrading a subscription (Silver to Gold).

      const res = await getApiSubscriptionBillingInfo();

      if (!res.isSuccess) {
        console.error('Error getting subscription billing info', JSON.stringify(res.error));
        Sentry.captureException(new Error('Error getting subscription billing info'));
        throw new Error(res.error.message);
      }

      // Handle PayPal subscriptions
      if (res.content.subscriptionType === 'paypal') {

        const paymentLinkResult = await getApiSubscriptionGeneratePaymentLinkV2(productId);

        if (!paymentLinkResult.isSuccess) {
          console.error(`Error while generating payment link for productId: ${productId}`, JSON.stringify(res.error));
          Sentry.captureException(new Error('Error while generating payment link'));
          throw new Error(res.error.message);
        }

        const link = paymentLinkResult.content.value;
        await this.createSubscription({ override: link }, reason)

        return;
      }

      // Handle legacy SL Trial Account subscriptions
      if (res.content.subscriptionPlanId === 'sl_trial_account') {
        const data = { product: productId, ...meta }
        await this.createSubscription(data, reason)
        return;
      }

      // Upgrade to a new plan despite not being one of the types above. Not sure if this is still used, but leaving it here for now.
      const changePlanResponse = await postApiSubscriptionChangePlan(productId);

      if (!changePlanResponse.isSuccess) {
        console.error(`Error while changing plan: ${productId}`, JSON.stringify(res.error));
        Sentry.captureException(new Error('Error while changing plan'));
        throw new Error(res.error.message);
      }

      EventBus.$emit(upgradeEvents.UPGRADE_SUCCESS)
      await userInfoStore.updateUserInfo()
    },
    async createSubscription(data, reasonOpened) {
      const { createSubscription } = useCreateSubscription()
      await createSubscription(data, reasonOpened)
    },
  },
}
