<script lang="ts" setup>
import { onMounted, computed, onUnmounted, inject } from 'vue'
import type { Ref } from 'vue'
import CropToolbar from '@/areas/editor/workspaces/preview/cropper/CropToolbar/CropToolbar.vue'
import MovableElement from '@/modules/SLMovable/MovableElement.vue'
import { shapes } from '@/modules/CustomLayouts/@data/shapes'
import { useVideoStore } from '@/areas/editor/store/useVideoStore'
import type { Directions, Area, Size } from '@/modules/SLMovable/@types/Movable'
import { useEditorFocusStore } from '@/store/editor/editorFocus'
import { resizeAspectLockedFromCenter } from '@/modules/SLMovable/helpers/resize/resizeAspectLockedFromCenter'
import { useCropsStore } from '@/areas/editor/store/useCropsStore'
import { useHistoryStore } from '@/areas/editor/store/useHistoryStore'
import { resizeAspectLocked } from '@/modules/SLMovable/helpers/resize/resizeAspectLocked'
import { useLayoutsStore } from '@/areas/editor/store/useLayoutsStore'
import { calculateRemainingAspectRatio } from '@/areas/editor/@data/prefabs/_helpers'
import { clamp } from 'lodash-es'

const props = defineProps<{
  id: string
  enableSnapping: boolean
}>()

const cropsStore = useCropsStore()
const crop = cropsStore.selectById(props.id)
const layoutsStore = useLayoutsStore()
const layout = computed(() => layoutsStore.selectById(crop.layoutId))

const videoStore = useVideoStore()

function resize(area: Area, directions: Directions) {

  if (!videoStore.videoElement) {
    return null
  }

  const { videoWidth, videoHeight } = videoStore.videoElement
  const inputSize = { width: videoWidth, height: videoHeight }
  const outputSize = { width: 1080, height: 1920 }

  if (layout.value?.presetId === 'split' || layout.value?.presetId === 'basecam') {
    resizeSplit(area, directions, inputSize, outputSize)
    return
  }

  const input = {
    width: area.width * inputSize.width,
    height: area.height * inputSize.height,
    x: area.x * inputSize.width,
    y: area.y * inputSize.height,
  }

  const output = {
    width: crop.feedData.width * outputSize.width,
    height: crop.feedData.height * outputSize.height,
    x: crop.feedData.x * outputSize.width,
    y: crop.feedData.y * outputSize.height,
  }

  const newSize = resizeAspectLockedFromCenter(
    output,
    { x: output.x + output.width, y: output.y + output.height },
    directions,
    { top: 0, left: 0, right: outputSize.width, bottom: outputSize.height },
    input,
    null)

  cropsStore.updateCropFeedDataById(props.id, {
    x: newSize.x / outputSize.width,
    y: newSize.y / outputSize.height,
    width: newSize.width / outputSize.width,
    height: newSize.height / outputSize.height,
  })
}

function resizeSplit(area: Area, directions: Directions, video: Size, feed: Size) {

  const isTopCrop = (layout.value.presetId === 'split' && crop.input.name === 'Facecam')
    || (layout.value.presetId === 'basecam' && crop.input.name === 'Gameplay')
  const otherCrop = cropsStore.entities.find((c) => c.layoutId === crop.layoutId && c.id !== props.id)!

  const cropArea = {
    x: area.x * video.width,
    y: area.y * video.height,
    width: area.width * video.width,
    height: area.height * video.height,
  }

  const cropResize = resizeAspectLocked(
    cropArea,
    {
      x: directions.includes('e') ? cropArea.x + cropArea.width : cropArea.x,
      y: directions.includes('s') ? cropArea.y + cropArea.height : cropArea.y,
    },
    directions,
    { top: 0, left: 0, right: video.width, bottom: video.height },
    { width: clamp(cropArea.width / cropArea.height, 9 / 15, calculateRemainingAspectRatio(9, 15)), height: 1 },
    null)

  cropsStore.updateCropAreaById(props.id, {
    x: cropResize.x / video.width,
    y: cropResize.y / video.height,
    width: cropResize.width / video.width,
    height: cropResize.height / video.height,
  })

  const newWidth = feed.width
  const newHeight = feed.width / cropResize.width * cropResize.height
  const newX = 0
  const newY = isTopCrop ? 0 : feed.height - newHeight

  cropsStore.updateCropFeedDataById(props.id, {
    x: newX / feed.width,
    y: newY / feed.height,
    width: newWidth / feed.width,
    height: newHeight / feed.height,
  })

  const otherCropAspectRatio = calculateRemainingAspectRatio(newWidth, newHeight)
  const otherCropWidth = otherCrop.feedData.width * feed.width
  const otherCropHeight = otherCropWidth / otherCropAspectRatio

  const otherNewWidth = feed.width
  const otherNewHeight = feed.width / otherCropWidth * otherCropHeight
  const otherNewX = 0
  const otherNewY = isTopCrop ? feed.height - otherNewHeight : 0

  cropsStore.updateCropFeedDataById(otherCrop.id, {
    x: otherNewX / feed.width,
    y: otherNewY / feed.height,
    width: otherNewWidth / feed.width,
    height: otherNewHeight / feed.height,
  })

  const otherCropArea = {
    x: otherCrop.x * video.width,
    y: otherCrop.y * video.height,
    width: otherCrop.width * video.width,
    height: otherCrop.height * video.height,
  }

  const otherCropResize = resizeAspectLockedFromCenter(
    otherCropArea,
    { x: otherCropArea.x + otherCropArea.width, y: otherCropArea.y + otherCropArea.height },
    directions,
    { top: 0, left: 0, right: video.width, bottom: video.height },
    { width: otherCropWidth, height: otherCropHeight },
    null)

  cropsStore.updateCropAreaById(otherCrop.id, {
    x: otherCropResize.x / video.width,
    y: otherCropResize.y / video.height,
    width: otherCropResize.width / video.width,
    height: otherCropResize.height / video.height,
  })

  cropsStore.state[otherCrop.id].input.shape = 'freeform'
}

const shape = computed(() => shapes.find((s) => s.value === crop.input.shape))

const editorFocusStore = useEditorFocusStore()
const focused = computed(() => {
  return editorFocusStore.focus && editorFocusStore.focus.type === 'crop' && editorFocusStore.focus.key === props.id
})

function setFocus() {
  editorFocusStore.setFocus('crop', props.id)
}

function handleKeyPress(event: KeyboardEvent) {

  if (!focused.value) return

  if (event.ctrlKey && event.key === 'd') {
    cropsStore.duplicateCropById(props.id)

    event.preventDefault()
    return false
  }

  if (event.ctrlKey || event.metaKey) {
    if (event.key === '}') {
      cropsStore.moveToForeground(props.id)

      event.preventDefault()
      return false
    } else if (event.key === '{') {
      cropsStore.moveToBackground(props.id)

      event.preventDefault()
      return false
    } else if (event.key === ']') {
      cropsStore.shift(props.id, +1)

      event.preventDefault()
      return false
    } else if (event.key === '[') {
      cropsStore.shift(props.id, -1)

      event.preventDefault()
      return false
    }
  }

  if (event.key === 'Backspace' || event.key === 'Delete') {

    if (crops.value.length > 1) {
      cropsStore.removeById(props.id)
      setTimeout(() => {
        const otherCrop = cropsStore.ids.find((id) => id !== props.id)
        if (otherCrop) {
          editorFocusStore.setFocus('crop', otherCrop)
        }
      }, 0)

      event.preventDefault()
      return false
    }
  }

  if (event.key === 'Escape') {
    if (focused.value) {
      editorFocusStore.unFocus()
      event.preventDefault()
      return false
    }
  }
}

onMounted(() => {
  window.addEventListener('keypress', handleKeyPress)
  window.addEventListener('keydown', handleKeyPress)
})

onUnmounted(() => {
  window.removeEventListener('keypress', handleKeyPress)
  window.removeEventListener('keydown', handleKeyPress)
})

const crops = cropsStore.whereLayoutIdIs(crop.layoutId)
const mouseover = inject<Ref<string | null>>('mouseover')!

const area = computed({
  get() {
    return {
      x: crop.x,
      y: crop.y,
      width: crop.width,
      height: crop.height,
    }
  },
  set(area: Area) {
    crop.x = area.x
    crop.y = area.y
    crop.width = area.width
    crop.height = area.height
  }
})

const historyStore = useHistoryStore()

const aspectLock = computed(() => {
  // This is for older templates with the 'lock' shape.
  // @ts-ignore
  if (shape?.value?.value === 'lock') {
    return {
      width: crop.width * videoStore.videoSize!.width,
      height: crop.height * videoStore.videoSize!.height,
    }
  } else if (shape.value?.aspectLock) {
    return shape.value.aspectLock
  } else {
    return null
  }
})

function onResizeOrMoveEnd() {
  historyStore.push()
  if (crop.storageKey) {
    localStorage.setItem(crop.storageKey, JSON.stringify({
      x: crop.x,
      y: crop.y,
      width: crop.width,
      height: crop.height,
      videoWidth: videoStore.videoSize?.width,
      videoHeight: videoStore.videoSize?.height,
    }))
  }
}
</script>

<template>
  <div
    :class="{
      'rounded-[100%]': crop.input.shape === 'circle',
    }"
    :style="{
      left: area.x * 100 + '%',
      top: area.y * 100 + '%',
      width: area.width * 100 + '%',
      height: area.height * 100 + '%',
    }"
    class="absolute transition-opacity group"
    @touchstart.stop="setFocus"
    @mousedown.stop="setFocus"
    @mouseover="mouseover = crop.id"
    @mouseleave="mouseover = null"
    @click.stop
  >
    <MovableElement :id="crop.id + '-crop'" move resize
      v-model="area"
      @resize="resize"
      @resize-end="onResizeOrMoveEnd"
      @move-end="onResizeOrMoveEnd"
      :shape="crop.input.shape === 'circle' ? 'circle' : 'rectangle'"
      :aspectLock="aspectLock"
      :bounds="{ top: 0, right: 1, bottom: 1, left: 0 }"
      :snap="enableSnapping && crop.input.name !== 'Facecam'"
      :min-size="6"
      :resize-handle-class="focused ? 'z-[250]' : ''"
      :handles="['nw', 'ne', 'se', 'sw']"
    >
      <template #move>
        <div
          class="absolute -inset-px border-2"
          :class="{ 'rounded-full': crop.input.shape === 'circle' }"
          :style="{ borderColor: crop.input.color }"
        />
      </template>
      <template #resize-direction>
        <div
          :style="{ backgroundColor: focused ? crop.input.color : 'transparent' }"
          class="h-3 w-3"
        />
      </template>
    </MovableElement>

    <div class="absolute left-1/2 top-0 -translate-x-1/2 -translate-y-1/2 flex flex-nowrap rounded pointer-events-none"
      :class="{ 'z-[250]': focused }"
      :style="{ backgroundColor: crop.input.color }"
    >
      <span ref="label" class="whitespace-nowrap px-2 py-1 text-xs text-black/80">
        {{ crop.input.name }}
      </span>
    </div>

    <CropToolbar :id="id" :show="focused" />
  </div>
</template>

<style scoped lang="scss"></style>
