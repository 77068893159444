import { useEditorStickersStore } from '@/store/editor/editorStickers'
import { useEditorCaptionsStore } from '@/store/editor/editorCaptions'
import { canGuard, canGuardWithPopup } from '@/Hooks/useGuard'
import { getLayout } from '@/data/layoutData'
import { useEditorClipInfoStore } from '@/store/editor/editorClipInfo'
import { useRoute } from 'vue-router'
import { useEditorMainStore } from '@/store/editor/editorMain'
import { useEditorFeedDataStore } from '@/store/editor/editorFeedData'
import { postApiRenders, getApiRenders } from '@/apis/streamladder-api/renders/renders'
import { getSegmentsFromStores } from '@/components/Clip2TikTok/renderingHelpers'
import { useUserInfoStore } from '@/store/user/userInfo'
import { isBefore, subMinutes } from 'date-fns'
import { useDebounceFn } from '@vueuse/core'
import * as Sentry from '@sentry/vue'
import logging from '@/logging'
import { useEditorVideoStore } from '@/store/editor/editorVideo'
import { setLayoutArguments } from '@/services/layoutHelper'
import { useFileUploads } from '@/components/Dialog/MultiUploadDialog/file-uploads/useFileUploads'
// @ts-ignore
import compatabilityChecker from '@/services/compatabilityChecker'
import type { CreateRenderDto } from '@/apis/streamladder-api/model'
import { useFeatureFlagEnabled } from '@/Hooks/useFeatureFlagEnabled'
import { useStickersStore } from '@/store/entity-system/useStickersStore'
import { requestUserSignInAsync } from '@/authentication/supabase'

export const useStreamladderRender = () => {
  const stickersStore = useStickersStore()
  const editorStickersStore = useEditorStickersStore()
  const editorCaptionsStore = useEditorCaptionsStore()
  const editorClipInfoStore = useEditorClipInfoStore()
  const editorFeedDataStore = useEditorFeedDataStore()
  const editorMainStore = useEditorMainStore()
  const editorVideoStore = useEditorVideoStore()
  const route = useRoute()
  const fileUploads = useFileUploads()

  const useWebcodecRenderer = useFeatureFlagEnabled('new-editor-v2')

  const preprocess = async () => {
    // Make sure the user is authenticated
    // Wait for the user to authenticate
    const userIsAuthenticated = await requestUserSignInAsync('Please login to render your video')
    if (!userIsAuthenticated)
      return {
        type: 'error',
        message: 'User is not authenticated',
      } as const

    const hasStickers = editorStickersStore.hasStickers
    if (hasStickers && !canGuardWithPopup('stickers')) {
      return {
        type: 'interrupted',
        message: 'User does not have enough access to stickers',
      } as const
    }

    const hasCaptions = editorCaptionsStore.hasCaptions
    if (hasCaptions && !canGuardWithPopup('captions')) {
      return {
        type: 'interrupted',
        message: 'User does not have enough access to captions',
      } as const
    }

    const hasPremiumLayout = Boolean(editorMainStore.layoutName && getLayout(editorMainStore.layoutName)?.premium)
    if (hasPremiumLayout && !canGuardWithPopup('layouts')) {
      return {
        type: 'interrupted',
        message: 'User does not have enough access to layouts',
      } as const
    }

    const hasGiphyStickers = stickersStore.entities.some((sticker) => sticker.type === 'giphy')
    if (hasGiphyStickers && !canGuardWithPopup('giphy-stickers')) {
      return {
        type: 'interrupted',
        message: 'User does not have enough access to Giphy stickers',
      } as const
    }

    const canServerSideRender = canGuard('server-side-rendering')

    if (editorClipInfoStore.isLocalFile) {
      const clipId = route.params.clipId as string
      const upload = fileUploads.selectById(clipId).value

      if (upload) {
        try {
          await upload.suspense().catch(console.error)
          await editorClipInfoStore.setClipByUploadId(clipId)

          if (upload.status !== 'finished') {
            Sentry.captureException(new Error(`Failed to upload file; upload status is '${upload.status}'`))
            return {
              type: 'error',
              message: `Failed to upload file; upload status is '${upload.status}'`,
            } as const
          }
        } catch (reason) {
          console.error(reason, JSON.stringify(upload))
          Sentry.captureException(new Error(`Upload failed with error '${reason}`))
          return {
            type: 'error',
            message: `Upload failed with error '${reason}'`,
          } as const
        }
      }
    }

    // the user cannot server side queue as they are free so preload the required data and send user to preprocessing
    if (!canServerSideRender) {
      // prepare ffmpeg data
      // redirect to preprocess where the state data will be reduced to a command string
      // and redirected to the render page for cors purposes
      setLayoutArguments(route.params.layout as string)
      const hasFfmpegString = editorMainStore.ffmpegStringArray.length > 0 || editorClipInfoStore.mp4Url
      if (!hasFfmpegString) {
        return {
          type: 'error',
          message: 'No ffmpeg string data',
        } as const
      }

      // check if user is on mobile and has a local file
      if (compatabilityChecker.isMobile()) {
        canGuardWithPopup('server-side-rendering')
        return {
          type: 'interrupted',
          message: 'Mobile does not support local file rendering',
        } as const
      }

      const query: Record<string, string> = {
        clipName: editorClipInfoStore.title,
        command: btoa(JSON.stringify(editorMainStore.ffmpegStringArray)),
        trimmedDurationMs: editorMainStore.trimmedDurationMs?.toString(),
        trimmedStartTime: editorMainStore.trimmedStartTime?.toString(),
        clipUrl: editorClipInfoStore.mp4Url,
      }

      return {
        type: 'local',
        generateUrl: `${location.origin}/generate?${new URLSearchParams(query).toString()}`,
      } as const
    }

    if (canServerSideRender) {
      // make sure the user has their file uploaded if they use server side queueing

      const canQueueRenders = canGuard('server-side-queueing')
      if (!canQueueRenders) {
        return {
          type: 'server',
          status: 'wait',
        } as const
      }

      // redirect to preprocess where the client will collect data and send it to the server
      return {
        type: 'server',
        status: 'ready',
      } as const
    }

    return {
      type: 'error',
      message: 'No render type',
    } as const
  }

  const trackClipCreation = (options: Awaited<ReturnType<typeof preprocess>>) => {
    const eventProperties = {
      Layout: editorMainStore.layoutName,
      Source: route.meta.clipSource,
      ...editorStickersStore.getLoggingData(),
      ...editorCaptionsStore.getLoggingData(),
      ...editorFeedDataStore.getLoggingData(),
      SelectedResolution: editorMainStore.outputWidth === 1080 ? 1080 : 720,
      SegmentCount: editorVideoStore.segments.length,
    }

    if (options.type === 'interrupted') {
      logging.trackEvent('Clip Creation Interrupted', {
        ...eventProperties,
        Error: options.message,
      })
      return
    }

    if (options.type === 'error') {
      logging.trackEvent('Clip Creation Error', {
        ...eventProperties,
        Error: options.message,
      })
      return
    }

    logging.trackEvent('Clip Created', {
      ...eventProperties,
      renderer: options.type,
      Queued: options.type === 'server' && options.status === 'ready',
    })
  }

  const getVideoRenderData = () => {
    const editorStickersStore = useEditorStickersStore()
    const stickersStore = useStickersStore()
    const editorCaptionsStore = useEditorCaptionsStore()
    const editorClipInfoStore = useEditorClipInfoStore()
    const editorMainStore = useEditorMainStore()
    const stickerData = useWebcodecRenderer.value
      ? stickersStore.entities
      : // strip out the visible and component properties
      editorStickersStore.selectedStickers.map(({ visible, component, ...properties }) => properties)

    const hasOverlay = stickerData.length || editorCaptionsStore.hasCaptions

    const segments = getSegmentsFromStores()

    if (useWebcodecRenderer.value) {
      for (const segment of segments) {
        segment.cropData?.sort((a, b) => (a.zIndex ?? 0) - (b.zIndex ?? 0))
      }
    }

    return {
      contentUrl: editorClipInfoStore.mp4Url,
      title: editorClipInfoStore.title,
      segments: segments,
      outputFPS: editorMainStore.outputWidth === 1080 ? 60 : 30,
      outputResolution: editorMainStore.outputWidth === 1080 ? 1080 : 720,
      overlay:
        hasOverlay || useWebcodecRenderer.value
          ? {
            Stickers: stickerData,
            Captions: editorCaptionsStore.captions,
            CaptionsWrapper: editorCaptionsStore.captionsWrapper,
            DurationMs: editorMainStore.videoDuration,
            captionsObject: {
              CaptionStyleSettings: editorCaptionsStore.captionStyleSettings,
              BaseOptions: editorCaptionsStore.baseOptions,
              captionPreset: editorCaptionsStore.captionPreset,
            },
          }
          : null,
    } as CreateRenderDto
  }

  const renderVideoOnServer = async () => {
    try {
      const res = await postApiRenders(getVideoRenderData())
      if (res == null)
        return {
          type: 'error',
          message: 'No response',
        } as const

      const taskId = res.value?.id
      if (!taskId)
        return {
          type: 'error',
          message: 'No taskId',
        } as const
      return {
        type: 'server',
        task: res.value,
      } as const
    } catch (e) {
      Sentry.captureException(e)
      return {
        type: 'error',
        message: 'Server error',
      } as const
    }
  }

  const trackClipServerRender = (options: Awaited<ReturnType<typeof renderVideoOnServer>>) => {
    const eventProperties = {
      Layout: editorMainStore.layoutName,
      Source: route.meta.clipSource,
      ...editorStickersStore.getLoggingData(),
      ...editorCaptionsStore.getLoggingData(),
      ...editorFeedDataStore.getLoggingData(),
      SelectedResolution: editorMainStore.outputWidth === 1080 ? 1080 : 720,
      SegmentCount: editorVideoStore.segments.length,
    }

    if (options.type === 'error') {
      logging.trackEvent('Clip Server Render Error', {
        ...eventProperties,
        Error: options.message,
      })
      return
    }

    logging.trackEvent('Clip Server Rendered', {
      ...eventProperties,
      taskId: options.task,
    })
  }

  const requestVideoRender = useDebounceFn(async () => {
    try {
      // Validate the current editor state
      // make sure the user is authenticated
      // make sure the user has a subscription
      // make sure the users does not use features that are not allowed
      // return the type of rendering required
      const preprocessResult = await preprocess()
      console.log('preprocessResult', preprocessResult)
      trackClipCreation(preprocessResult)
      if (preprocessResult.type === 'error') return preprocessResult
      if (preprocessResult.type === 'local') {
        // user is on the client, rendering the video.
        // The url contains all relevant data to render the video
        // for cors reasons we need hard redirect to the url
        console.log('redirecting to', preprocessResult.generateUrl)
        window.location.href = preprocessResult.generateUrl
        return preprocessResult
      }
      if (preprocessResult.type === 'interrupted') {
        // user is not allowed to render
        return preprocessResult
      }

      if (preprocessResult.type === 'server') {
        // The rendering needs to be done on the server
        if (preprocessResult.status === 'wait') {
          // make sure a silver user does not have more than one render
          // wait for latest render information
          // if the user is rendering, show a popup
          const isRendering = await fetchIsAlreadyRendering()
          if (isRendering) {
            const canContinue = canGuardWithPopup('server-side-queueing', {
              title: 'One at a Time, Please!',
              subtitle:
                'Your Silver Membership allows one active render. Choose to wait, or upgrade to Gold for multiple renders.',
            })
            if (!canContinue) return { type: 'error', message: 'There is already a render running' }
          }
        }
        // user is ready to render on the server

        const renderResult = await renderVideoOnServer()
        trackClipServerRender(renderResult)
        if (renderResult.type === 'error') return renderResult
        if (renderResult.type === 'server') {
          // trigger a refresh of the render list
          // this wil trigger a poll
          return renderResult
        }
      }
    } catch (e) {
      Sentry.captureException(e)
      return {
        type: 'error',
        message: 'Error',
      }
    }
    return {
      type: 'error',
      message: 'Error',
    }
  }, 1000)

  return {
    preprocess,
    renderVideoOnServer,
    requestVideoRender,
    getVideoRenderData,
  }
}

async function fetchIsAlreadyRendering() {
  const userInfoStore = useUserInfoStore()
  if (!userInfoStore.isLoggedIn) return false

  const response = await getApiRenders({ Status: 'rendering' })
  const renders = response.value?.items ?? []

  // date-fns function to check if a render is older than 10 minutes using date-fns library
  return renders.some((render) => {
    const renderCreatedAt = new Date(render.createdAt ?? 0)
    const tenMinutesAgo = subMinutes(new Date(), 10)
    return render.status === 'rendering' && !isBefore(renderCreatedAt, tenMinutesAgo)
  })
}
