<script setup lang="ts">
import { useCampaignComponent } from '@/data/campaigns'
import { useHead } from '@unhead/vue'
import { computed, nextTick, onMounted, ref, watch } from 'vue'
import {
  getGetApiRendersQueryKey,
  postApiRendersIdRetry,
  putApiRendersIdRead,
  useGetApiRendersId,
} from '@/apis/streamladder-api/renders/renders'
import { useRouter } from 'vue-router'
import FeatureShowcase from '@/components/Clip2TikTok/FeatureShowcase.vue'
import AsyncComponent from '@/components/Campaigns/AsyncComponent.vue'
import LottieAnimation from '@/components/LottieAnimation.vue'
import StatusRow from '@/components/Clip2TikTok/StatusRow.vue'
import { useRouteQuery } from '@vueuse/router'
import { isArray } from 'lodash-es'
import { useUserInfoStore, onUserInfoReady, onUserInfoReadyAsync } from '@/store/user/userInfo'
import type { RenderDto } from '@/apis/streamladder-api/model'
import { queryClient } from '@/services/QueryClient'
import { useLiveRenderStatus } from '@/Hooks/useLiveRenderStatus'
import { dashboardRouteNames } from '@/areas/dashboard/routeNames'
import IconSaxRefresh2 from '@/components/Icons/iconsax/IconSaxRefresh2.vue'
import { Button } from '@/components/ui/button'
import { NotificationQueryKey } from '@/modules/NotificationService/NotificationService'
import logging from '@/logging'
import { tiers } from '@/enums/tiers'
import { useFeatureFlagEnabled } from '@/Hooks/useFeatureFlagEnabled'
import OnboardingSurveyFlow from '@/components/Onboarding/OnboardingSurveyFlow.vue'

const LoaderComponent = useCampaignComponent('generate-loader')
const userInfoStore = useUserInfoStore()
const useWebcodecRenderer = useFeatureFlagEnabled('new-editor-v2');

const router = useRouter()

useHead({
  title: 'Generating video...',
  bodyAttrs: {
    class: 'bg-base-100',
  },
})

type PageStatus = 'idle' | 'rendering' | 'waiting' | 'error' | 'success'
const pageStatus = ref<PageStatus>('idle')

type Status = 'idle' | 'in-progress' | 'success' | 'error' | 'in-endless-progress'

const setupStatus = ref<Status>('in-endless-progress')

const videoHasErrors = ref(false)
const taskId = useRouteQuery('task')

const cleanTaskId = computed(() => {
  return (isArray(taskId.value) ? taskId.value[0] : taskId.value) || ''
})

watch(cleanTaskId, () => {
  if (!cleanTaskId.value || cleanTaskId.value === '') {
    pageStatus.value = 'error'
  }
}, { immediate: true })

const { data, refetch } = useGetApiRendersId(cleanTaskId, {
  query: {
    enabled: computed(() => userInfoStore.isAuthenticated),
    refetchInterval: 8000,
    refetchIntervalInBackground: true,
  },
})

const { overlayStatus, overlayPercentage, uploadStatus, renderStatus, renderPercentage, isFinished, videoUploadStatus, preprocessStatus, preprocessPercentage } =
  useLiveRenderStatus(cleanTaskId)

watch(isFinished, (isFinished) => {
  if (isFinished) {
    refetch()
  }
})

const finishedRenderTaskId = ref<string | null>(null);

const checkForSuccess = async (renderTask: RenderDto) => {
  
  if (showOnboardingSurvey.value) {
    return
  }

  if (renderTask?.status === 'success') {
    renderStatus.value = 'success'
    pageStatus.value = 'success'
    await putApiRendersIdRead(renderTask.id)
    await queryClient.invalidateQueries({ queryKey: getGetApiRendersQueryKey() })

    await onUserInfoReadyAsync()

    const { href } = userInfoStore.tier === tiers.FREE
      ? router.resolve({ name: dashboardRouteNames.editorResult, query: { renderId: renderTask.id } })
      : router.resolve({ name: dashboardRouteNames.contentPublisher.render, params: { renderId: renderTask.id } })

    finishedRenderTaskId.value = renderTask.id!

    window.location.replace(href);

  } else if (renderTask?.status === 'failed') {
    renderStatus.value = 'error'
    pageStatus.value = 'error'
    videoHasErrors.value = true
  }
}

const setupState = async () => {
  setupStatus.value = 'in-endless-progress'
  if (taskId.value) {
    setupStatus.value = 'success'
    pageStatus.value = 'rendering'
    return
  }
  setupStatus.value = 'error'
  return
}

onMounted(() => {
  nextTick(async () => {
    await setupState()
    await showOnboardingSurveyOnceForFreeUsers();
  })
})

const showOnboardingSurvey = ref(false)

const showOnboardingSurveyOnceForFreeUsers = async () => {
  await onUserInfoReadyAsync();
  const userHasAlreadyAnsweredSurvey = await checkIfUserHasAnsweredSurvey();
  showOnboardingSurvey.value = userInfoStore.tier === tiers.FREE && !userHasAlreadyAnsweredSurvey;
}

const checkIfUserHasAnsweredSurvey = async () => {
  const hasAnswered = localStorage.getItem('hasAnsweredOnboardingSurvey');
  return !!hasAnswered;
}

const retry = async () => {
  logging.trackEvent('Generate Page: Retry Render Clicked', {
    taskId: cleanTaskId.value,
  })

  if (cleanTaskId.value) {
    videoHasErrors.value = false
    renderStatus.value = 'idle'
    pageStatus.value = 'idle'
    await postApiRendersIdRetry(cleanTaskId.value)
    await queryClient.invalidateQueries({ queryKey: NotificationQueryKey })
  }
}

async function handleSurveyEnd() {
  localStorage.setItem('hasAnsweredOnboardingSurvey', 'true');
  showOnboardingSurvey.value = false;
  if (data.value && data.value.value) {
    await checkForSuccess(data.value.value)
  }
}

watch([data], ([response]) => {
  if (response && response.value) {
    checkForSuccess(response.value)
  }
}, { immediate: true })
</script>

<template>
  <FeatureShowcase>
    <OnboardingSurveyFlow v-model:open="showOnboardingSurvey" @surveyEnd="handleSurveyEnd" />

    <div class="flex w-full items-center justify-center">
      <AsyncComponent :is="LoaderComponent || 'div'" class-name="w-3/5 md:w-1/3 lg:w-1/2">
        <lottie-animation url="./lottie/walking-chick.json" :loop="true" :autoPlay="true" v-if="!useWebcodecRenderer" />
        <lottie-animation url="./lottie/race-car.json" :loop="true" :autoPlay="true" v-else />
      </AsyncComponent>
    </div>

    <div v-if="pageStatus === 'waiting'" class="flex w-full flex-col text-center">
      <h1 class="text-2xl"><strong>Waiting on server</strong></h1>
      <p class="text-company-primary text-opacity-75">
        You already have a video rendering on our servers. We will start rendering your video as soon as the other is
        done
      </p>
    </div>
    <div v-else class="flex w-full flex-col text-center">
      <template v-if="renderStatus === 'in-endless-progress'">
        <h1 class="text-2xl">
          <strong>Your video has been queued</strong>
        </h1>
        <p class="text-company-primary text-opacity-75">
          Your video is in the queue and will start rendering shortly. This may take a few minutes.
        </p>
      </template>
      <template v-else>
        <h1 class="text-2xl">
          <strong>Rendering your video</strong>
        </h1>
        <p v-if="userInfoStore.tier !== tiers.GOLD" class="text-company-primary text-opacity-75">
          We are rendering your video. This may take a short while.
        </p>
        <p v-else class="text-company-primary text-opacity-75">
          We are rendering your video in the background and will notify you when it’s ready!
        </p>
      </template>
    </div>

    <div v-if="renderStatus === 'in-endless-progress'" class="mx-auto flex flex-col space-y-2">
      <status-row status-text="Queued..." :status="renderStatus" />
    </div>

    <div v-else class="mx-auto flex flex-col space-y-2">
      <status-row v-if="preprocessPercentage > 0" status-text="Preprocessing input video" :status="preprocessStatus" :progress-percentage="preprocessPercentage" />
      <status-row v-if="!useWebcodecRenderer" status-text="Loading resources" :status="uploadStatus" />
      <status-row
        v-if="!useWebcodecRenderer"
        status-text="Applying stickers & captions"
        :status="overlayStatus"
        :progress-percentage="overlayPercentage"
      />
      <status-row status-text="Rendering video" :status="renderStatus" :progress-percentage="renderPercentage" />
      <status-row v-if="useWebcodecRenderer" status-text="Uploading video" :status="videoUploadStatus" />
    </div>

    <div v-if="videoHasErrors" class="self-center rounded-xl border border-red-700 bg-red-100 p-4">
      <h5>Error</h5>
      <p>We couldn't process your video.</p>
      <div class="flex flex-col gap-1 p-4">
        <Button class="rounded-full" @click.stop="retry">
          Try again
          <IconSaxRefresh2 class="h-4 w-4" />
        </Button>
        <p class="mt-4 text-center text-xs">Is it still not working?</p>
        <Button
          as="a"
          href="/video-support"
          variant="outline"
          class="rounded-full"
          @click="logging.trackEvent('Generate Page: Find Out Why Clicked', { taskId: cleanTaskId })"
        >
          Find out why
        </Button>
      </div>
    </div>
  </FeatureShowcase>
</template>
