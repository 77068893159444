<script lang="ts" setup>
import { computed, onMounted, onUnmounted, watch } from 'vue'
import upgradeEvents from '@/events/upgradeEvents'
import { useFeature } from '@/data/features'
import { usePlanUpgrade } from '@/Hooks/usePlanUpgrade'
import unwrap from '@/helpers/unwrap'
import plans from '@/data/plans'
import { useEditorStickersStore } from '@/store/editor/editorStickers'
import compatabilityChecker from '@/services/compatabilityChecker'
import logging from '@/logging'
import { useModal } from '@/Hooks/useModal'
import eventBus from '@/eventBus'
import { useUserInfoStore, onUserInfoReadyAsync } from '@/store/user/userInfo'
import { canGuard } from '@/Hooks/useGuard'
import type { UpgradeDialogPayload } from '@/helpers/upgradeDialog'
import UpgradeDialogCards from '@/components/Dialog/Gold/UpgradeDialogCards.vue'
import { useFeatureFlagVariantEnabled } from '@/Hooks/useFeatureFlagEnabled'
import TwoStepsABTest from '@/components/Dialog/Gold/Tests/TwoStepsABTest.vue'

const { isLoading, error } = usePlanUpgrade()

const { isShowing, payload, close, open } = useModal<UpgradeDialogPayload>({
  closeEvent: upgradeEvents.CLOSE_UPGRADE_DIALOG,
})

const userInfoStore = useUserInfoStore()

async function onOpen(payload: UpgradeDialogPayload | undefined) {
  if (await userInfoStore.ensureLoggedInWithDialog()) {
    await onUserInfoReadyAsync()
    if (!payload
      || ('feature' in payload && canGuard(payload.feature))
      || ('tier' in payload && payload.tier === userInfoStore.tier)) {
      return
    }
    open(payload)
  }
}

onMounted(() => {
  eventBus.$on(upgradeEvents.OPEN_UPGRADE_DIALOG, onOpen)
  eventBus.$on(upgradeEvents.UPGRADE_SUCCESS, close)
  injectSenjaScript();
})

const injectSenjaScript = () => {
  if (!document.getElementById('senyo')) {
    const script = document.createElement('script')
    script.src = "https://static.senja.io/dist/platform.js#";
    script.async = true;
    script.type = "text/javascript";
    script.id = 'senyo';
    document.body.append(script);
  }
};

onUnmounted(() => {
  eventBus.$off(upgradeEvents.OPEN_UPGRADE_DIALOG, onOpen)
  eventBus.$off(upgradeEvents.UPGRADE_SUCCESS, close)
})

const featureString = computed(() => payload.value && 'feature' in payload.value ? payload.value.feature : undefined)
const feature = useFeature(featureString)
const dialog = computed(() => {
  const dialog = feature.value && 'dialog' in feature.value ? feature.value.dialog : undefined
  return {
    title: payload.value?.title || dialog?.title,
    subtitle: payload.value?.subtitle || dialog?.subtitle,
  }
})

const editorStickersStore = useEditorStickersStore()

watch(isShowing, (value) => {
  if (value) {
    logging.trackEvent('Upgrade SubscriptionDialog Opened', {
      // Why the dialog was opened
      ReasonOpened: payload.value?.reason,
      Payload: payload.value,

      Intentional: !!payload.value?.intentional,

      // Properties for user action/choices
      HasStickers: editorStickersStore.hasStickers,
      HasPremiumTemplate: false,

      // Properties of the client
      IsMobile: compatabilityChecker.isMobile(),
      Is32Bit: compatabilityChecker.is32Bit(),
    })
  }
})

const title = computed(() => dialog.value?.title ?? 'This could be the start of <br><h1 class="text-[#9490ff]">something big.</h1>')

const tier = computed(() => {
  return unwrap.values(plans).find((p) => p.tier === feature.value?.tier)
})

const subtitle = computed(() => {
  const htmlContent = dialog.value?.subtitle ?? 'Join over 200k users who are already using StreamLadder everyday.'
  return htmlContent.replace(/\{\{ tier }}/gi, tier.value?.title ?? 'Gold')
})

const handleClose = (payload: UpgradeDialogPayload) => {
  if (!isLoading.value) {
    close(payload)
  }
}

const roundedCards = useFeatureFlagVariantEnabled('rounded-tier-cards', 'rounded-cards');
</script>

<template>
  <Teleport to="body">
    <Transition name="bounce">
      <template v-if="roundedCards">
        <div
          @click.self="() => handleClose(payload)"
          class="modal h-screen w-screen p-0 light"
          :class="{ 'modal-open': isShowing }"
          v-if="isShowing"
        >
          <TwoStepsABTest
            :title="title"
            :subtitle="subtitle"
            :reason="payload?.reason ?? 'unknown'"
            :payload="payload"
            :close="close"
            :tiers="unwrap.values(plans)"
            :isLoading="isLoading"
            :error="error"
            :handle-close="handleClose"
          >
            <div class="senja-embed p-4 bg-white/70 rounded-lg min-h-[300px]" data-id="ed7c0cbb-a0df-4550-a2a6-92e24e1e8b3e" data-mode="shadow" data-lazyload="false"></div>
          </TwoStepsABTest>
        </div>
      </template>
      <template v-else>
        <div
          @click.self="() => handleClose(payload)"
          class="modal h-screen w-screen p-0 light"
          :class="{
            'modal-open': isShowing,
          }"
          v-if="isShowing"
        >
          <UpgradeDialogCards
            :title="title"
            :subtitle="subtitle"
            :reason="payload?.reason ?? 'unknown'"
            :payload="payload"
            :close="close"
            :tiers="unwrap.values(plans)"
            :isLoading="isLoading"
            :error="error"
            :handle-close="handleClose"
          >
            <div class="senja-embed p-4 bg-white/70 rounded-lg min-h-[300px]" data-id="ed7c0cbb-a0df-4550-a2a6-92e24e1e8b3e" data-mode="shadow" data-lazyload="false"></div>
          </UpgradeDialogCards>
        </div>
      </template>
    </Transition>
  </Teleport>
</template>

<style scoped lang="scss">
.bounce-enter-active {
  animation: bounce-in 0.25s;
  transition: opacity 0.25s ease;
}

.bounce-enter-from,
.bounce-leave-to {
  opacity: 0;
}

@keyframes bounce-in {
  0% {
    opacity: 0;
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
</style>
