<script setup lang="ts">
import { Dialog, DialogContent, DialogTitle, DialogDescription } from '@/components/ui/dialog'
import { Button } from '@/components/ui/button'
import IconSaxArrowRight from '@/components/Icons/iconsax/IconSaxArrowRight.vue'
import { useLocalStorage } from '@vueuse/core'
import { posthog } from 'posthog-js'
import { onMounted } from 'vue'

const showElgatoContestDialog = useLocalStorage('showElgatoContestDialog', true)

const openInNewTab = () => {
  posthog.capture('Elgato Dialog Clicked');
  window.open('https://streamladder.com/contest2024/?utm_source=sl-website&utm_campaign=contest2024&utm_content=cta-bottom', '_blank');
};

onMounted(() => {
  posthog.capture('Elgato Dialog Shown');
});
</script>

<template>
  <Dialog v-model:open="showElgatoContestDialog">
    <DialogContent class="p-0 max-h-[100dvh] max-w-3xl min-h-[450px]">
      <div class="flex flex-col-reverse md:flex-row gap-4">
        <div class="flex flex-col gap-4 w-full h-full">
          <img class="md:hidden w-full h-auto transition-transform" src="/images/contest/green-green/banner.jpg" alt="Elgato Contest Promo Banner" draggable="false">
          <div class="flex flex-col gap-4 p-6 max-w-full md:max-w-[250px] h-full md:items-center">
            <DialogTitle class="text-xl font-bold">
              OUR BIGGEST GIVEAWAY TO DATE
            </DialogTitle>
            <DialogDescription class="mb-auto leading-normal font-light text-[14px]">
              Create just <em>one</em> video tutorial showing how you use StreamLadder, and you could win <strong class="font-bold">ACTUAL CASH</strong> prizes and awesome <strong class="font-bold">Elgato Gear</strong>!
              <br><br>
              We don't care about how big your channel is <strong>—creativity</strong> is all that matters!
            </DialogDescription>

            <Button class="mt-4 cursor-pointer" size="lg" @click="openInNewTab">
              Learn How to Enter
              <IconSaxArrowRight class="h-5 w-5" />
            </Button>

            <p class="text-sm text-gray-500 text-center">Win up to $5000!</p>
          </div>
        </div>
        <div class="relative pointer-events-none hidden md:block">
          <img draggable="false" src="/images/contest/popup.png" class="absolute pointer-events-none h-[450px] right-0 top-0" alt="logo" >
        </div>
      </div>
    </DialogContent>
  </Dialog>
</template>

<style scoped lang="scss">

</style>
