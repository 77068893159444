import type { Tier, TierLevel } from '@/data/plans'
import { computed, ref } from 'vue'
import { useSubscriptionIntervals } from '@/Hooks/useSubscriptionIntervals'
import { onUserInfoReadyAsync, useUserInfoStore } from '@/store/user/userInfo'
import CanUpgradeAccounts from '@/mixins/canUpgradeAccounts.js'
import * as Sentry from '@sentry/vue'
import { useUserSubscriptionStore } from '@/store/user/userSubscription'
import EventBus from '@/eventBus'
import mainEvents from '@/events/mainEvents'
import { putApiSubscriptionIdResume } from '@/apis/streamladder-accounts/subscription/subscription'
import type { SubscriptionStatus } from '@/apis/streamladder-accounts/model'

const isLoading = ref<TierLevel | null>(null)
const error = ref<'configuration-unknown' | 'checkout-failed' | null>(null)

const { interval } = useSubscriptionIntervals()

const upgrade = async (tier: Tier, reason: string, discountCode?: string | null, intervalOverride?: 'month' | 'year') => {

  if (isLoading.value === null) {

    await onUserInfoReadyAsync();

    error.value = null;

    const userInfoStore = useUserInfoStore();
    if (userInfoStore.trialStatus !== 'active' && tier.tier <= userInfoStore.tier) {
      return
    }

    const subscriptionStore = useUserSubscriptionStore();
    const subscription = computed(() => subscriptionStore.subscription);
    const status = computed(() => subscription.value?.status?.toLowerCase() as SubscriptionStatus);

    const intervalOrOverride = intervalOverride
      ? intervalOverride
      : interval.value;

    isLoading.value = tier.key;

    const plans = [...tier.plans];
    const plan = plans.find((p) => p.key === intervalOrOverride);

    if (plan === undefined) {
      console.error(JSON.stringify({ tier, intervalOrOverride }));
      Sentry.captureException(new Error('Configuration is unknown while upgrading'));
      error.value = 'configuration-unknown';
      return;
    }

    const meta = {
      fromTier: userInfoStore.tier,
      toTier: tier.tier,
      interval: intervalOrOverride,
    };

    if (subscription.value && status.value === 'paused') {

      const result = await putApiSubscriptionIdResume(subscription.value!.id);

      if (!result.isSuccess) {

        console.error(JSON.stringify(meta));
        Sentry.captureException(new Error('Resuming plan failed while upgrading'));
        error.value = 'checkout-failed';

        const message = result.error?.errorCode === 502
          ? result.error?.errorMessage
          : "We're trying to resume your account's plan, however the process is being blocked by our payment provider. Please contact support if you have any questions!";

        EventBus.$emit(mainEvents.ERROR, message);

        return;
      }
    }

    try {
      await CanUpgradeAccounts.methods.attemptCheckout(plan.paddle, discountCode, reason, meta)
    } catch (errorMessage) {

      console.error(JSON.stringify(errorMessage));
      Sentry.captureException(new Error('Checkout failed'));
      error.value = 'checkout-failed';

      const message = errorMessage === 502
        ? errorMessage
        : "We're trying to upgrade your account, however the process is being blocked by our payment provider. Please contact support if you have any questions!";

      EventBus.$emit(mainEvents.ERROR, message);
    }

    isLoading.value = null
  }
}

export const usePlanUpgrade = () => {
  return { isLoading, error, upgrade }
}
