import { useEditorClipInfoStore } from '@/store/editor/editorClipInfo'
import { startupFromLocalFileByClipId } from '@/areas/editor/startup/startupFromLocalFile'
import { startupFromYoutubeClipByClipId } from '@/areas/editor/startup/startupFromYoutubeClip'
import { startupFromTwitchClipByClipId } from '@/areas/editor/startup/startupFromTwitchClip'
import { startupFromKickClipByClipId } from '@/areas/editor/startup/startUpFromKickClip'

import { useFeatureFlagEnabled } from '@/Hooks/useFeatureFlagEnabled'
import { useRouter } from 'vue-router'
import { useHistoryStore } from '@/areas/editor/store/useHistoryStore'
import { editorRouteNames } from '@/areas/editor/routeNames'
import { ref, computed } from 'vue'
import { startupFromTwitchVod, startupFromTwitchVodByClipId } from '@/areas/editor/startup/startupFromTwitchVod'
import type { ClipGptClipDto } from '@/apis/streamladder-api/model'
import { startupFromKickCxClipByClipId } from '@/areas/editor/startup/startUpFromKickCxClip'

export function useStartup() {

  const historyStore = useHistoryStore()
  const editorClipInfoStore = useEditorClipInfoStore()
  const useNewEditor = useFeatureFlagEnabled('new-editor-v2')
  const router = useRouter()
  
  const controller = ref<AbortController | null>(null)
  const signal = computed<AbortSignal | null>(() => controller.value?.signal ?? null)
  const promise = ref<Promise<unknown> | null>(null)

  async function abort() {
    controller.value?.abort()
    controller.value = null
    await promise.value
  }
  
  router.beforeEach((to, from, next) => {

    if (to.name !== editorRouteNames.root) {
      abort()
    }
    
    next()
  })

  const downloadError = ref<string | null>(null)

  async function handleTransaction(transaction: () => Promise<{ error: string | null }>) {
    const response = await transaction()
    if (response.error) {
      downloadError.value = response.error
    } else {
      await historyStore.replace()
    }
  }

  async function createProject(source: string) {

    controller.value = new AbortController()
    await router.push({ name: editorRouteNames.root })
    signal.value?.throwIfAborted()

    editorClipInfoStore.source = source
    editorClipInfoStore.loadingState = {
      state: 'loading',
      description: 'Initializing editor...'
    }
  }

  async function setPromise<T>(fn: () => Promise<T>) {
    promise.value = fn()
    return promise.value as T
  }

  return {

    downloadError: downloadError,
    
    abortStartup: abort,

    async startup(clipId: string, source: string) {
      await setPromise(async () => {
        if (!useNewEditor.value) {
          // Old editor route
          await router.push({ name: 'editor', params: { clipId, source } })
        } else {

          controller.value = new AbortController()

          // New editor route
          if (router.currentRoute.value.name !== editorRouteNames.root) {
            await router.push({ name: editorRouteNames.root });
            signal.value?.throwIfAborted()
          }

          editorClipInfoStore.id = clipId
          editorClipInfoStore.source = source
          editorClipInfoStore.loadingState = {
            state: 'loading',
            description: 'Initializing editor...'
          }

          await historyStore.push()

          switch (source) {
            case 'twitch-clip':
              return await handleTransaction(() => startupFromTwitchClipByClipId(clipId, { signal }))
            case 'twitch-vod':
              return await handleTransaction(() => startupFromTwitchVodByClipId(clipId, { signal }))
            case 'youtube-clip':
              return await handleTransaction(() => startupFromYoutubeClipByClipId(clipId, { signal }))
            case 'kick-clip':
              return await handleTransaction(() => startupFromKickClipByClipId(clipId, { signal }))
            case 'kick-cx-clip':
              return await handleTransaction(() => startupFromKickCxClipByClipId(clipId, { signal }))
            case 'local-file':
              return await handleTransaction(() => startupFromLocalFileByClipId(clipId, { signal }))
            default:
              throw new Error('Not implemented')
          }
        }
      })
    },
    
    async startupByClipId(clipId: string, source: string) {
      return await setPromise(async () => {
        await createProject(source)
        switch (source) {
          case 'twitch-clip':
            return await startupFromTwitchClipByClipId(clipId, { signal })
          case 'youtube-clip':
            return await startupFromYoutubeClipByClipId(clipId, { signal })
          case 'kick-clip':
            return await startupFromKickClipByClipId(clipId, { signal })
          case 'kick-cx-clip':
            return await startupFromKickCxClipByClipId(clipId, { signal })
          case 'local-file':
            return await startupFromLocalFileByClipId(clipId, { signal })
          default:
            throw new Error('Not implemented')
        }
      })
    },

    async startupFromTwitchClipByClipId(clipId: string) {
      await setPromise(async () => {
        await createProject('twitch-clip')
        await startupFromTwitchClipByClipId(clipId, { signal })
      })
    },

    async startupFromYoutubeClipByClipId(clipId: string) {
      await setPromise(async () => {
        await createProject('youtube-clip')
        await startupFromYoutubeClipByClipId(clipId, { signal })
      })
    },
    
    async startupFromKickClipByClipId(clipId: string) {
      await setPromise(async () => {
        await createProject('kick-clip')
        await startupFromKickClipByClipId(clipId, { signal })
      })
    },

    async startupFromLocalFileByClipId(clipId: string) {
      await setPromise(async () => {
        await createProject('local-file')
        await startupFromLocalFileByClipId(clipId, { signal })
      })
    },

    async startupFromTwitchVod(clip: ClipGptClipDto) {
      await setPromise(async () => {
        await createProject('twitch-vod')
        await startupFromTwitchVod(clip, { signal })
      })
    },
  }
}
