import type { VideoFragment } from '../types'
import { VideoWorkerManager } from '@/webcodec-renderer/VideoWorkerManager'
import { Rectangle, type ColorSource } from 'pixi.js'
import { getBackgroundCropData } from '@/modules/SLVideoplayer/helpers'
import type { RendererCrop } from '@/areas/editor/store/useRendererData'
import type { Ref } from 'vue'

export class WorkerRenderer {

  static renderer?: WorkerRenderer;

  private videoFragments: Map<string, VideoFragment> = new Map()
  private crops: Ref<RendererCrop[] | undefined>

  private backgroundVideo: CanvasImageSource | undefined
  private canvas: HTMLCanvasElement;
  private _isReady: boolean;
  private worker: VideoWorkerManager;
  private currentVideoSegment: HTMLVideoElement | undefined;
  private frameSource: CanvasImageSource | undefined;
  private initializePromise: Promise<null>;
  private initializeResolve: () => void;
  private aspectRatio: number;

  private lastResizeWidth = 0;
  private isUpdating = false;
  private disposed = false;
  private screen: Rectangle = new Rectangle(0,0,0,0);

  constructor(width: number, height: number, backgroundColor?: ColorSource) {

    this.initializeResolve = () => {};
    this.initializePromise = new Promise(resolve => {
        this.initializeResolve = () => resolve(null);
    })

    this.worker = new VideoWorkerManager({ width, height, backgroundColor, live: true });

    this.worker.initializeForPreview(this.initializeResolve);
    this.canvas = this.worker.canvas;
    this.aspectRatio = this.canvas.height / this.canvas.width;
    this.canvas.style.width = '100%';

    this._isReady = true;

    this.ticker.update();
    window.requestAnimationFrame(this.animationLoop.bind(this));

    WorkerRenderer.renderer = this;
  }

  async animationLoop() {

    if (this.currentVideoSegment
      // && !(this.currentVideoSegment as HTMLVideoElement).paused
    ) {
        await this.ticker.update();
    }

    if (!this.disposed) {
      window.requestAnimationFrame(this.animationLoop.bind(this));
    }
  }

  public get app() {
    return {
        resize: () => {
            const containerSize = this.currentVideoSegment?.parentElement?.getBoundingClientRect();
            if (containerSize) {
                const width = Math.round(containerSize.width);
                const height = Math.round(width * this.aspectRatio);

                if (this.lastResizeWidth !== width) {
                    this.lastResizeWidth = width;
                    this.worker.resize(width, height)
                    this.ticker.update();
                }
            }
        },
    }
  }

  public get view() {
    return this.canvas;
  }

  public get ticker() {
    return {
        update: async () => {

            if (!this.currentVideoSegment) return;
            if (this.isUpdating) return;

            this.isUpdating = true;
            try {
                await this.initializePromise;
                const timestamp = this.currentVideoSegment.currentTime * 1_000_000;

                if (this.crops?.value && this.frameSource) {
                  const videoFragments = selectCurrentVideoFragments(this.crops.value, this.currentVideoSegment, this.frameSource)
                  const map = new Map<string, VideoFragment>()
                  map.set('background', this.videoFragments.get('background') as VideoFragment)
                  for (const fragment of videoFragments) {
                    map.set(fragment.key, fragment)
                  }
                  
                  const { width, height } = sizeOfCanvasImageSource(this.frameSource)
                  this.worker.renderVideoFrames(map, timestamp, width, height);
                } else {
                  const width = this.currentVideoSegment.videoWidth;
                  const height = this.currentVideoSegment.videoHeight;
                  this.worker.renderVideoFrames(this.videoFragments, timestamp, width, height)
                }
            } catch (e) {
                console.error(e);
            }

            this.isUpdating = false;
        },
        start: () => {},
        stop: () => {},
        pause: () => {},
    }
  }

  public get height() {
    return this.canvas.height;
  }

  public get width() {
    return this.canvas.width;
  }

  get isReady() {
    return this._isReady;
  }

  public resizeTo(element: HTMLElement) {
    // console.log('resize to: ', element);
    this.worker.resize(element.clientWidth, element.clientHeight);

    this.screen = new Rectangle(0, 0, element.clientWidth, element.clientHeight);
  }

  public setCrops(crops: Ref<RendererCrop[]>, videoElement: HTMLVideoElement, frameSource: CanvasImageSource) {

    this.currentVideoSegment = videoElement;
    this.frameSource = frameSource;

    // Store crops proxy objects
    this.crops = crops
  }

  public setVideo(identifier: string, fragment: VideoFragment) {
    this.currentVideoSegment = fragment.source as HTMLVideoElement;
    this.videoFragments.set(identifier, fragment)
  }

  public removeVideo(identifier: string) {
    if (this.videoFragments.get(identifier)) {
      this.videoFragments.delete(identifier)
    }
  }

  setBackground(src: CanvasImageSource | null, addBlurEffect = true) {
    if (!src) {
      this.backgroundVideo = undefined
      this.removeVideo('background')
      return
    }
    this.backgroundVideo = src
    const { width, height } = sizeOfCanvasImageSource(src)
    this.setVideo('background', {
      key: '',
      sourceEndMs: 0,
      sourceStartMs: 0,
      source: src,
      cropData: getBackgroundCropData(width, height, this.width, this.height),
      feedData: {
        x: 0,
        y: 0,
        w: 1,
        h: 1,
      },
      effect: addBlurEffect
        ? [
            {
              type: 'blur',
              strength: Math.round(window.innerHeight / height),
              quality: 3,
            },
          ]
        : [],
      zIndex: 0,
    })
  }

  destroy() {
    this.worker?.terminate();
    this.disposed = true;
  }
}

function selectCurrentVideoFragments(crops: RendererCrop[], video: HTMLVideoElement | undefined, frameSource: CanvasImageSource) {

  const fragments: VideoFragment[] = [];
  for (const crop of crops) {

    if (!video) {
      break;
    }

    const currentTimeMs = video.currentTime * 1000;
    if (crop.startMs <= currentTimeMs && crop.endMs >= currentTimeMs) {
      fragments.push({
        key: crop.key,
        zIndex: crop.z,
        source: frameSource,
        sourceStartMs: crop.startMs,
        sourceEndMs: crop.endMs,
        effect: crop.shape === 'circle'
          ? [{ type: 'rounded' }]
          : [],
        cropData: {
          x: crop.cropData.x,
          y: crop.cropData.y,
          w: crop.cropData.width,
          h: crop.cropData.height,
          ratio: 1,
        },
        feedData: {
          x: crop.cropData.feedData.x,
          y: crop.cropData.feedData.y,
          w: crop.cropData.feedData.width,
          h: crop.cropData.feedData.height,
          ratio: 1,
        },
      });
    }
  }
  return fragments;
}

function sizeOfCanvasImageSource(source: CanvasImageSource) {
  if ('videoWidth' in source) {
    return { width: source.videoWidth, height: source.videoHeight }
  } else if ('codedWidth' in source) {
    return { width: source.codedWidth, height: source.codedHeight }
  } else if (typeof source.width === 'number' && typeof source.height === 'number') {
    return { width: source.width, height: source.height }
  } else {
    throw new Error('Could not determine size of CanvasImageSource')
  }
}
