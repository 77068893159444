<script lang="ts" setup>
import FullCalendar from '@fullcalendar/vue3'
import { ref, computed, watch, onMounted } from 'vue'
import { useContentPublisherEventHandling } from '@/components-v2/content-publisher/_handling'
import { useContentPublisherStore } from '@/components-v2/content-publisher/_store'
import ConnectSocialDialog from '@/areas/dashboard/components/ConnectSocialDialog.vue'
import ContentPublisherPostDialog from '@/components-v2/content-publisher/ContentPublisherPostDialog.vue'
import { useResizeObserver, useWindowFocus } from '@vueuse/core'
import ContentPublisherEvent from '@/components-v2/content-publisher/ContentPublisherEvent.vue'
import ContentPublisherDay from '@/components-v2/content-publisher/ContentPublisherDay.vue'
import { contentPublisherConfig } from '@/components-v2/content-publisher/_config'
import { useScreen } from '@/Hooks/useScreen'
import { useRoute } from 'vue-router'
import ContentPublisherMobile from "@/components-v2/content-publisher/ContentPublisherMobile.vue";

const emit = defineEmits<{
  (name: 'schedule', event: { videoId: string; date: string }): void
  (name: 'schedule', event: { postDate: string | undefined }): void
}>()

const shouldConnectSocials = ref(false)
const managePostId = ref<string | null>(null)

const route = useRoute()

const currentRoutePost = computed(() => route.query?.post);
watch(currentRoutePost, async (currentRoutePost) => {
  if (currentRoutePost) {
    managePostId.value = currentRoutePost as string;
  }
}, { immediate: true });

const handlers = useContentPublisherEventHandling({
  onDrop: (event) => emit('schedule', event),
  onSchedule: (event) => emit('schedule', event),
  onConnectSocials: () => (shouldConnectSocials.value = true),
  onUpdate: (id, payload) => store.reschedulePost(id, payload),
})

const store = useContentPublisherStore()

const options = computed(() => ({
  ...contentPublisherConfig,
  ...handlers.value,
  events: store.events,
}))

const calendar = ref<InstanceType<typeof FullCalendar>>()
const fullCalendarApi = computed(() => calendar.value?.getApi())

const container = ref<HTMLDivElement>()
useResizeObserver(container, () => fullCalendarApi.value?.updateSize())

// update info every 30 seconds while window is open
const interval = ref<NodeJS.Timeout>()
const focus = useWindowFocus()

const currentRouteDate = computed(() => route.query?.date);
watch([currentRouteDate, fullCalendarApi], async () => {
  if (route.query?.date && fullCalendarApi.value) {
    const date = new Date(route.query.date as string)
    fullCalendarApi.value?.gotoDate(date)
  }
  await store.fetch()
}, { immediate: true })

watch(focus, async () => {
  if (focus.value) {
    await store.refetch()
    interval.value = setInterval(store.refetch, 30_000)
  } else {
    clearInterval(interval.value)
    interval.value = undefined
  }
})

const isDesktop = useScreen('lg')
watch(isDesktop, (isDesktop, wasDesktop) => {
  if (wasDesktop && !isDesktop) {
    fullCalendarApi.value?.changeView('dayGridWeek')
    fullCalendarApi.value?.updateSize()
  }
})
</script>

<template>
  <section ref="container" class="relative h-full flex-1">
    <FullCalendar ref="calendar" :options="options">
      <template v-slot:eventContent="eventContent">
        <ContentPublisherEvent :event-content="eventContent" @click="(id) => (managePostId = id)" />
      </template>
      <template v-slot:dayCellContent="dayCellContent">
        <ContentPublisherDay :content="dayCellContent" />
      </template>
    </FullCalendar>

    <div v-if="store.isPerformingBackgroundAction" class="absolute right-0 top-16">
      <div
        class="h-4 w-4 animate-spin rounded-full border-2 border-current border-l-transparent text-company-primary drop-shadow-[0_0_1px_theme('colors.company-primary.200')] dark:text-company-primary-100 dark:drop-shadow-[0_0_1px_theme('colors.company-primary.900')]"
      />
    </div>
  </section>
  <ConnectSocialDialog v-model="shouldConnectSocials" />
  <ContentPublisherPostDialog v-model="managePostId" />
</template>

<style lang="scss">
@use 'sass:math';

.fc {
  @apply flex h-full flex-col gap-4 layer-1;

  .fc-daygrid,
  .fc-scrollgrid,
  .fc-scroller-harness,
  .fc-scroller,
  .fc-daygrid-body,
  .fc-scrollgrid-section,
  .fc-scrollgrid-section table,
  .fc-scrollgrid-section > td {
    @apply h-full;
  }

  .fc-view-harness {
    @apply max-lg:min-h-0 max-lg:shrink max-lg:grow max-lg:basis-0 max-lg:overflow-scroll;

    & > * {
      width: math.div(100%, 2) * 7;

      @screen md {
        width: math.div(100%, 3) * 7;
      }

      @screen lg {
        width: 100%;
      }
    }
  }

  .fc-scrollgrid-section.fc-scrollgrid-section-header {
    @apply h-0;
  }

  .fc-scrollgrid-section.fc-scrollgrid-section-body {
    @apply h-full;
  }

  .fc-button {
    @apply rounded-full border-transparent bg-company-primary-50 transition-all px-2 py-1 text-sm;
    margin: 0 !important;

    &:focus,
    &:hover {
      @apply border-transparent bg-company-primary-50 shadow-none outline-transparent #{!important};
    }

    &:active {
      @apply scale-90 bg-company-primary-100;
    }
  }

  // month / week toggle
  .fc-toolbar .fc-toolbar-chunk:last-child {
    .fc-button:not(:disabled):not(.fc-button-active) {
      @apply layer-2 text-brand-state-text-secondary hover:text-white border-transparent shadow-none;

      &:focus {
        @apply shadow-none outline-transparent;
      }
    }

    .fc-button-active {
      @apply border-transparent bg-brand-primary text-white;
    }
  }

  .fc-event,
  .fc-daygrid-day-event {
    @apply m-0 p-0;
  }

  table,
  thead,
  tbody,
  td,
  th,
  .fc-scrollgrid {
    @apply border-none;
  }

  .fc-scrollgrid-section-sticky th {
    @apply layer-1;
  }

  .fc-toolbar {
    @apply grid grid-cols-2 items-center gap-2 lg:grid-cols-3;

    &.fc-header-toolbar {
      @apply mb-0 mx-2;
    }

    .fc-toolbar-title {
      @apply m-0 text-base font-light lg:text-3xl;
    }

    .fc-toolbar-chunk {
      @apply -order-1 w-full first:order-1 first:w-auto last:hidden last:w-auto first:justify-end;
      @apply flex items-center gap-4 whitespace-nowrap;
      @apply last:layer-2 last:gap-1 last:rounded-full last:p-1;

      @screen lg {
        @apply order-none last:flex #{ !important };
      }
    }

    @screen lg {
      @apply grid-cols-3;

      .fc-toolbar-chunk {
        @apply justify-self-center justify-center first:justify-self-start last:justify-self-end;
      }
    }
  }

  .fc-event-draggable {
    @apply cursor-grab;
  }

  .fc-event-dragging {
    @apply cursor-grabbing;
  }

  .fc-daygrid-day-frame {
    @apply pointer-events-auto relative;
  }

  .fc-dayGridMonth-view {
    .fc-daygrid-day-frame {
      @apply flex h-full flex-col gap-2 px-2 py-3;

      @screen md {
        min-height: 92px;
      }

      @screen lg {
        min-height: 120px;
      }

      @screen xl {
        min-height: 150px;
      }
    }
  }

  .fc-daygrid-day-events {
    @apply grid grid-cols-1 gap-0 p-2;
    @apply before:hidden after:hidden;

    .fc-event {
      @apply m-0 p-0 pt-2;
    }
  }

  .fc-daygrid-dot-event.fc-event-mirror,
  .fc-daygrid-dot-event:hover,
  .fc-daygrid-dot-event:active {
    @apply bg-transparent;
  }

  .fc-daygrid-day-events {
    --row-height: 0;
    grid-auto-rows: minmax(var(--row-height), auto);
  }

  .fc-dayGridWeek-view {
    .fc-daygrid-day-events {
      --row-height: calc(calc(100vh - 300px) / 50);
    }

    .fc-daygrid-day-bottom {
      grid-row-start: 24;
    }

    [data-hide-if-week-view] {
      @apply hidden;
    }
  }

  .fc-daygrid-day-number {
    @apply static;
  }

  .fc-daygrid-day:hover .fc-daygrid-day-overlay {
    @apply visible cursor-pointer text-brand-state-hover-cta;
  }

  .fc-daygrid {
    @apply -m-1 border-none;
  }

  .fc-theme-standard td,
  .fc-theme-standard th,
  .fc-theme-standard .fc-scrollgrid {
    @apply border-none;
  }

  .fc-scrollgrid-section .fc-col-header .fc-col-header-cell {
    @apply px-1 pb-2 pt-1;
    @apply lg:pb-1;
  }

  .fc-scrollgrid-sync-inner {
    @apply layer-2 rounded-xl border border-surface-panel-border transition-all dark:border-transparent;
  }

  .fc-day-disabled .fc-scrollgrid-sync-inner,
  .fc-day-past .fc-scrollgrid-sync-inner {
    @apply bg-zinc-200 dark:bg-zinc-200;
  }

  .fc-day-disabled .fc-scrollgrid-sync-inner,
  .fc-day-past .fc-scrollgrid-sync-inner {
    @apply layer-2 bg-opacity-25 dark:bg-opacity-25;
  }

  td.fc-daygrid-day.fc-day {
    @apply h-full bg-transparent p-1;
    @apply pointer-events-none;
  }

  .fc-col-header-cell-cushion {
    @apply text-zinc-900 dark:text-white;
  }

  .fc-day-today .fc-scrollgrid-sync-inner {
    @apply bg-brand-state-hover-bg border-brand-state-active-border;
  }

  .fc-daygrid-day-overlay {
    @apply rounded-xl hover:bg-brand-state-hover-bg active:bg-brand-state-hover-bg;
    @apply border-2 border-surface-panel-border bg-surface-panel hover:border-brand-state-hover-border active:border-brand-state-active-border;
    @apply text-brand-primary;
  }

  .fc-button:disabled {
    @apply hover:text-white bg-zinc-600 text-brand-state-text-secondary dark:bg-zinc-100 dark:text-zinc-950;
  }

}
</style>
