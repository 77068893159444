<template>
  <div
    @click.self="close"
    :class="{
      'modal-open': show,
    }"
    class="modal"
  >
    <div class="layer-1 modal-box flex flex-col md:flex-row max-w-max p-0">
      <div class="flex flex-col items-center gap-2 p-6">
        <div class="sl-flat-picker-wrapper">
          <FlatPicker
            v-model="date"
            :config="{
              altInput: true,
              altInputClass: 'hidden',
              inline: true,
              static: true,
              enableTime: false,
              dateFormat: 'Y-m-d',
              time_24hr: true,
              minDate: 'today',
              maxDate: addMonths(new Date(), 2),
              onDayCreate: onDayCreate,
              onMonthChange: onMonthChange,
              locale: {
                firstDayOfWeek: 1,
              },
            }"
            class="form-control text-foreground"
            placeholder="Select date"
            name="date"
          />
        </div>
        <hr class="bg-black" />
        <div class="flex flex-col items-center gap-2">
          <span>Quick action</span>
          <div class="flex flex-row justify-around gap-4">
            <button @click="selectRandomTime(7, 12, 'morning')" class="flex flex-col items-center gap-1 hover:scale-105 transition-all">
              <MorningBold v-if="DayTime === 'morning'" class="h-8 w-8" />
              <MorningLine v-else class="h-8 w-8" />
              <span>Morning</span>
            </button>
            <button @click="selectRandomTime(12, 16, 'noon')" class="flex flex-col items-center gap-1 hover:scale-105 transition-all">
              <SunBold v-if="DayTime === 'noon'" class="h-8 w-8" />
              <SunLine v-else class="h-8 w-8" />
              <span>Noon</span>
            </button>
            <button @click="selectRandomTime(16, 19, 'afternoon')" class="flex flex-col items-center gap-1 hover:scale-105 transition-all">
              <AfternoonBold v-if="DayTime === 'afternoon'" class="h-8 w-8" />
              <AfternoonLine v-else class="h-8 w-8" />
              <span>Afternoon</span>
            </button>
            <button @click="selectRandomTime(19, 23, 'evening')" class="flex flex-col items-center gap-1 hover:scale-105 transition-all">
              <MoonBold v-if="DayTime === 'evening'" class="m-1 h-6 w-6" />
              <MoonLine v-else class="m-1 h-6 w-6" />
              <span>Evening</span>
            </button>
          </div>
        </div>
        <hr />
        <div class="layer-1 form-control flex flex-row justify-center items-center gap-2">
          Time
          <input v-model="time" @change="DayTime = undefined" type="time" class="time-input bg-transparent pl-2" />
        </div>
        <div class="modal-action">
          <Button variant="outline" @click="close" class="  ">Cancel</Button>
          <Button variant="primary" @click="pickDateTime" class=" ">Pick time</Button>
        </div>
      </div>
      <div class="flex md:w-64 flex-col gap-2 bg-surface-panel-100 p-6 relative">
        <ScheduledPostOnDayList :date="date" />
        <span v-if="contentPublisherStore.isFetching" class="absolute bottom-4 right-4 w-4 h-4 border-brand-state-active-border border-2 rounded-full border-t-transparent animate-spin" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, defineAsyncComponent, ref, watch } from 'vue'
import 'flatpickr/dist/flatpickr.css'
import { addMonths, endOfMonth, format, isSameDay, startOfMonth } from 'date-fns'
import MorningLine from '@/components/Icons/Normalized/DaytimeIndicators/MorningLine.vue'
import SunLine from '@/components/Icons/Normalized/DaytimeIndicators/SunLine.vue'
import MoonLine from '@/components/Icons/Normalized/DaytimeIndicators/MoonLine.vue'
import AfternoonLine from '@/components/Icons/Normalized/DaytimeIndicators/AfternoonLine.vue'
import MorningBold from '@/components/Icons/Normalized/DaytimeIndicators/MorningBold.vue'
import SunBold from '@/components/Icons/Normalized/DaytimeIndicators/SunBold.vue'
import AfternoonBold from '@/components/Icons/Normalized/DaytimeIndicators/AfternoonBold.vue'
import MoonBold from '@/components/Icons/Normalized/DaytimeIndicators/MoonBold.vue'
import { Button } from '@/components/ui/button'
import ScheduledPostOnDayList from '@/areas/dashboard/components/ScheduledPosts/ScheduledPostOnDayList.vue'
import { useContentPublisherStore } from '@/components-v2/content-publisher/_store'
import { watchImmediate, useLocalStorage } from '@vueuse/core'
import { debounce } from 'lodash-es'

const FlatPicker = defineAsyncComponent(() => import('vue-flatpickr-component'))

const props = defineProps<{
  modelValue?: Date
  show: boolean
}>()
const emit = defineEmits<{
  (e: 'update:modelValue', modelValue: Date | undefined): void
  (e: 'close'): void
}>()

const date = ref<string | null>(null)
const time = ref<string | null>(null)
const DayTime = ref<string | null>(null)

const dateTime = computed(() => {
  if (!date.value) return null
  return new Date(`${date.value}T${time.value}`)
})

const contentPublisherStore = useContentPublisherStore()
const timePreference = useLocalStorage('timePreference', format(new Date(), 'HH:mm'))

watch(
  () => props.show,
  (value, oldValue) => {
    if (value && !oldValue) {
      if (!props.modelValue) {
        date.value = format(new Date(), 'yyyy-MM-dd')
        time.value = timePreference.value
        return
      }
      date.value = format(props.modelValue, 'yyyy-MM-dd')
      time.value = format(props.modelValue, 'HH:mm')
    }
  }
)

watch(time, time => {
  timePreference.value = time
})

const selectRandomTime = (from: number, to: number, zone: string) => {
  DayTime.value = zone
  const hours = Math.floor(Math.random() * (to - from + 1) + from)
  const minutes = Math.floor(Math.random() * 60)
  time.value = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`
}

const pickDateTime = () => {
  emit('update:modelValue', dateTime.value)
  emit('close')
}

const close = () => {
  emit('close')
}

const displayedMonth = ref(startOfMonth(new Date()))
// fetch per week
watchImmediate(displayedMonth, debounce((month) => {
  contentPublisherStore.fetchByDateRange(startOfMonth(month), endOfMonth(month))
}, 1000, { leading: false, trailing: true }))

const onMonthChange = (selectedDates: Date[], dateStr: string, instance: any) => {
  displayedMonth.value = new Date(instance.currentYear, instance.currentMonth)
}

const onDayCreate = (dObj: Date, dStr: string, fp: any, dayElem: HTMLElement) => {
  const event = contentPublisherStore.posts.some((post) => {
    return isSameDay(new Date(post.scheduledAt), dayElem.dateObj)
  })
  // console.log(event)
  if (event) dayElem.innerHTML += "<span class='event absolute left-[15px] bottom-0.5 h-2 w-2 bg-brand-primary rounded-full block'></span>"
}
</script>

<style>
.sl-flat-picker-wrapper {

  div.flatpickr-calendar {
    @apply bg-surface-panel-100 border-surface-panel-border;
  }

  span.flatpickr-weekday:not(.selected),
  span.flatpickr-day:not(.selected) {
    @apply fill-current text-foreground;
  }

  div.flatpickr-months {
    .flatpickr-prev-month,
    .flatpickr-month,
    .flatpickr-next-month {
      @apply fill-current text-foreground;
    }

    input.cur-year[disabled] {
      @apply fill-current text-foreground opacity-30;
    }
  }

  .flatpickr-day.flatpickr-disabled,
  .flatpickr-day.flatpickr-disabled:hover,
  .flatpickr-day.prevMonthDay,
  .flatpickr-day.nextMonthDay,
  .flatpickr-day.notAllowed,
  .flatpickr-day.notAllowed.prevMonthDay,
  .flatpickr-day.notAllowed.nextMonthDay {
    @apply text-foreground opacity-30;
  }
}

/* Could not get dark:[&::-webkit-calendar-picker-indicator]:invert to work */
:is(.dark .time-input)::-webkit-calendar-picker-indicator {
  @apply invert;
}
</style>
