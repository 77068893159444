<script setup lang="ts">
import { useMovableElementContext } from '@/modules/SLMovable/useMovableElementContext'
import type { Directions, DirectionX, DirectionY } from '@/modules/SLMovable/@types/Movable'
import { resizeAutomatically } from '@/modules/SLMovable/helpers/resize/resizeAutomatically'
import { cover } from '@/modules/SLMovable/helpers/fit'
import { computed } from 'vue'
import { debounce } from 'lodash-es'

const props = defineProps<{
  id: string,
  handleClass?: string, 
  include?: (keyof typeof handles)[] 
}>()

const emit = defineEmits<{
  (event: 'resizeStart', direction: Directions): void
  (event: 'resize', area: { x: number; y: number; width: number; height: number }, directions: Directions): void
  (event: 'resizeEnd'): void
}>()

const handles = {
  n: { cursor: 'n-resize', directions: ['n'], angle: 0 },
  ne: { cursor: 'ne-resize', directions: ['n', 'e'], angle: 45 },
  e: { cursor: 'e-resize', directions: ['e'], angle: 90 },
  se: { cursor: 'se-resize', directions: ['s', 'e'], angle: 135 },
  s: { cursor: 's-resize', directions: ['s'], angle: 180 },
  sw: { cursor: 'sw-resize', directions: ['s', 'w'], angle: 225 },
  w: { cursor: 'w-resize', directions: ['w'], angle: 270 },
  nw: { cursor: 'nw-resize', directions: ['n', 'w'], angle: 315 },
} as const satisfies Record<string, { cursor: Readonly<string>; directions: Directions; angle: Readonly<number> }>

const { source, localArea, aspectRatio, resizingFrom, minSize, bounds, scaleX, scaleY, container } = useMovableElementContext()!

function determineTouchPoint(event: MouseEvent | TouchEvent) {

  const { pageX, pageY } = 'pageX' in event ? event : event.touches[0]

  // For some reason this is more accurate than @vueuse useElementBounding, sadly.
  const containerRect = container.value?.getBoundingClientRect()

  if (!containerRect) {
    return {
      x: 0,
      y: 0
    };
  } else {
    return {
      x: pageX - containerRect.x - window.scrollX,
      y: pageY - containerRect.y - window.scrollY,
    };
  }
}

function determineAspectRatio(event: MouseEvent | TouchEvent) {
  if (event.shiftKey) {
    return {
      width: localArea.value.width,
      height: localArea.value.height,
    }
  } else if (aspectRatio.value !== null) {
    // If external aspect ratio is provided, convert it to a relative aspect ratio
    // e.g. 1:1 aspect ratio in a video container would become (1/16):(1/9)
    return {
      width: scaleX.value(aspectRatio.value.width),
      height: scaleY.value(aspectRatio.value.height),
    }
  } else {
    return null
  }
}

function determineMinSize(event: MouseEvent | TouchEvent) {

  const aspectRatio = determineAspectRatio(event)

  const size = {
    width: scaleX.value(minSize.value),
    height: scaleY.value(minSize.value),
  }

  if (aspectRatio) {
    return cover(aspectRatio, size)
  } else {
    return size
  }
}

function resize(event: TouchEvent | MouseEvent) {

  const touchPoint = determineTouchPoint(event)

  const relativeTouchPoint = {
    x: scaleX.value(touchPoint.x),
    y: scaleY.value(touchPoint.y),
  }

  if (!resizingFrom.value) {
    return
  }

  const aspectRatio = determineAspectRatio(event)

  const resize = resizeAutomatically(source.value, relativeTouchPoint, resizingFrom.value, {
    aspectRatio: aspectRatio,
    centerOrigin: event.altKey,
    bounds: bounds.value,
    snap: null,
    minSize: determineMinSize(event),
  })

  if (resize) {
    localArea.value = resize
    emit('resize', localArea.value, resizingFrom.value)
  }
}

const debouncedResize = debounce(resize, 0)
const debouncedResizeEnd = debounce(resizeEnd, 0)

function resizeStart(directions: Directions) {
  emit('resizeStart', directions)
  resizingFrom.value = directions

  window.addEventListener('mousemove', debouncedResize)
  window.addEventListener('touchmove', debouncedResize)

  window.addEventListener('mouseup', debouncedResizeEnd)
  window.addEventListener('touchend', debouncedResizeEnd)
}

const debouncedResizeStart = debounce(resizeStart, 0)

function resizeEnd() {

  emit('resizeEnd')

  window.removeEventListener('mousemove', debouncedResize)
  window.removeEventListener('touchmove', debouncedResize)

  window.removeEventListener('mouseup', debouncedResizeEnd)
  window.removeEventListener('touchend', debouncedResizeEnd)
}

function is(handle: { directions: Directions }, direction: DirectionX | DirectionY) {
  return handle.directions.includes(direction)
}

const handlesToDraw = computed(() => {
  if (props.include) {
    return Object.fromEntries(props.include.map(key => [key, handles[key]]))
  } else {
    return handles
  }
})
</script>

<template>
  <div
    v-for="handle in handlesToDraw"
    :key="handle.cursor"
    :style="{ cursor: handle.cursor }"
    @mousedown="debouncedResizeStart(handle.directions)"
    @touchstart="debouncedResizeStart(handle.directions)"
    class="absolute grid place-items-center p-1.5"
    :class="[handleClass, {
      'left-0 -translate-x-1/2': is(handle, 'w'),
      'right-0 translate-x-1/2': is(handle, 'e'),
      'top-0 -translate-y-1/2': is(handle, 'n'),
      'bottom-0 translate-y-1/2': is(handle, 's'),
      'left-1/2 -translate-x-1/2': !is(handle, 'w') && !is(handle, 'e'),
      'top-1/2 -translate-y-1/2': !is(handle, 'n') && !is(handle, 's'),
    }]"
  >
    <slot name="direction" :direction="handle" :angle="handle.angle" />
  </div>
</template>

<style scoped lang="scss"></style>
