import { defineStore } from 'pinia'
import { useUserInfoStore } from './userInfo'
import { tiers } from '@/enums/tiers'
import { getApiSubscriptionBillingInfo } from '@/apis/streamladder-accounts/subscription/subscription'

interface UserSubscriptionState {
  isLoading: boolean
  subscription: UserSubscription | null
}

export const useUserSubscriptionStore = defineStore('userSubscription', {
  state: (): UserSubscriptionState => {
    return {
      isLoading: true,
      subscription: null,
    }
  },
  actions: {
    async fetchSubscriptionData(forceFetch = false) {

      // Check if subscription is already fetched and not forced to fetch a new one
      if (this.subscription != null && !forceFetch) return

      const res = await getApiSubscriptionBillingInfo();
      if (res.isSuccess && res.content) {
        this.subscription = {
          ...res.content!,
          type: res.content.subscriptionType!,
        };
        this.subscription.status = res.content.state!;
      } else {
        // If no subscription is found, set a default one.
        this.subscription = {
          status: SubscriptionStates.ACTIVE,
          tier: 0,
          type: '',
        }
      }
      this.isLoading = false
    },
  },
  getters: {
    isPaddleSubscription: (state: UserSubscriptionState) => state.subscription?.type === PaymentProviders.PADDLE,
    canDowngrade: (state: UserSubscriptionState) => {
      const userInfo = useUserInfoStore()
      return userInfo.plan == 'gold'
    },
    plan: (state: UserSubscriptionState) => (tiers.toString(state.subscription ? state.subscription.tier : tiers.FREE)),
    isSubscriptionExpired: (state: UserSubscriptionState) =>
      state.subscription?.endDate == null ? false : new Date(state.subscription.endDate) < new Date(),
  },
})

export type SubscriptionStatus = keyof typeof SubscriptionStates

export const SubscriptionStates = {
  NONE: '',
  ACTIVE: 'active',
  PAUSED: 'paused',
  CANCELLED: 'cancelled',
  UPGRADED: 'upgraded',
} as const

export interface UserPayment {
  amount: number
  currency: string
  date?: Date
}

export interface PaddlePaymentInfo {
  paymentMethod: string
  cardType: string
  LastFourDigits: string
  ExpiryDate: string
}

export const PaymentProviders = {
  PADDLE: 'paddle',
  PAYPAL: 'paypal',
  STREAMLADDER: 'sl',
} as const

export interface UserSubscription {
  status: SubscriptionStatus
  state: Lowercase<SubscriptionStatus>
  signupDate: Date
  endDate?: Date
  id: string
  lastPayment: UserPayment
  nextPayment?: UserPayment
  resumePayment?: UserPayment
  planId: string
  updatePaymentDetailsUrl: string
  subscriptionPlanId: string
  cancelUrl: string
  billingInfo?: PaddlePaymentInfo
  type: string
  tier: number
}
