<script lang="ts" setup>
import DashboardNavigation from '@/areas/dashboard/layout/nav/DashboardNavigation.vue'
import { ScrollArea } from '@/components/ui/scroll-area'
import { useScreen } from '@/Hooks/useScreen'
import { useHead } from '@unhead/vue'
import { useTheme } from '@/Hooks/useTheme'
import IconSaxArrowRight from '@/components/Icons/iconsax/IconSaxArrowRight.vue'
import { Button } from '@/components/ui/button'
import { useUserInfoStore } from '@/store/user/userInfo'
import { useFeatureFlagEnabled, useElgatoContestBannerV2 } from '@/Hooks/useFeatureFlagEnabled'
import { posthog } from 'posthog-js'
import { useRoute } from 'vue-router'
import { watch, capitalize, computed } from 'vue'

const isDesktop = useScreen('lg')
const { theme } = useTheme()
useHead({
  bodyAttrs: {
    class: theme
  },
})

const userInfoStore = useUserInfoStore()
const isElgatoContestActive = useFeatureFlagEnabled('elgato-contest');
const showElgatoContest = computed(() => isElgatoContestActive.value && userInfoStore.tier > 0)

const { gradient } = useElgatoContestBannerV2();
const route = useRoute()
const openContestInNewTab = () => {
  posthog.capture('Elgato Small Banner Clicked', { location: route.name ? `Dashboard ${capitalize(route.name as string)}` : 'Dashboard' });
  window.open('https://streamladder.com/contest2024/?utm_source=sl-website&utm_campaign=contest2024&utm_content=cta-bottom', '_blank');
};
</script>

<template>
  <main class="bg-surface-bg min-h-[100dvh] lg:h-[100dvh] grid" :class="showElgatoContest ? 'grid-rows-[auto,_1fr]' : ''">
    <div @click="openContestInNewTab" v-if="showElgatoContest" class="flex w-full items-center justify-center p-3 cursor-pointer" :class="gradient">
      <div class="flex h-full items-center justify-center pl-4 gap-4 leading-[1.2]">
        <p class="font-semibold text-white drop-shadow">
          Show us how you use StreamLadder and win up to $5000!
        </p>
        <button class="btn-success btn-sm btn h-6 rounded-md border-none bg-white px-2 text-[11px] uppercase text-black/75 drop-shadow-md hover:bg-white/90 md:h-9 md:px-4 md:text-[13px]">
          Learn more <IconSaxArrowRight class="ml-1 h-4 w-4" />
        </button>
      </div>
    </div>
    <section class="dashboard mx-auto grid min-[1920px]:max-w-[1920px] min-h-0 w-full">
      <Component :is="isDesktop ? ScrollArea : 'div'" class="dashboard__view">
        <section class="h-full mx-0 sm:mx-4 pb-4 mt-0 min-h-0 lg:m-6 lg:ml-0 lg:pb-0">
          <div
            ref="view"
            class="h-full w-full min-h-0 rounded-2xl border border-surface-panel-border bg-surface-panel text-brand-state-text-primary shadow-lg"
          >
            <slot name="router-view" v-if="$slots['router-view']" />
            <RouterView v-else />
          </div>
        </section>
      </Component>

      <!-- Navigation is placed after view intentionally to ensure any flyouts overlap the view. Grid Area is used for placement -->
      <header class="dashboard__nav will-change-contents lg:flex lg:flex-col lg:min-h-0">
        <DashboardNavigation class="p-4 lg:p-6" />
      </header>
    </section>
  </main>
</template>

<style lang="scss">
.dashboard {
  font-family: 'Metropolis', sans-serif;
  grid-template-rows: auto auto 1fr;
  grid-template-columns: 100%;
  grid-template-areas:
    'navigation'
    'view';

  @screen lg {
    grid-template-rows: 1fr;
    grid-template-columns: auto 1fr;
    grid-template-areas: 
      'navigation view';
  }

  .dashboard__nav {
    grid-area: navigation;
  }

  .dashboard__view {
    grid-area: view;
    // For some reason this prevents the view from overflowing the grid.
    @screen lg {
      grid-column-end: none;
    }
  }

  .dashboard__footer {
    grid-area: footer;
  }
}
</style>
