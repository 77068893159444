<script lang="ts" setup>
import { useUserInfoStore } from '@/store/user/userInfo'
import { ref } from 'vue'
import DashboardHamburgerButton from '@/areas/dashboard/layout/nav/DashboardHamburgerButton.vue'
import { useRouter, useRoute, type RouteRecordName } from 'vue-router'
import { dashboardRouteNames } from '@/areas/dashboard/routeNames'
import { tiers } from '@/enums/tiers'
import IconSaxHappyemoji from '@/components/Icons/iconsax/IconSaxHappyemoji.vue'
import DashboardAccountInfoFlyout from '@/areas/dashboard/layout/nav/DashboardAccountInfoFlyout.vue'
import IconSaxDiagram from '@/components/Icons/iconsax/IconSaxDiagram.vue'
import IconSaxHomeTrendUp from '@/components/Icons/iconsax/IconSaxHomeTrendUp.vue'
import IconSaxShare from '@/components/Icons/iconsax/IconSaxShare.vue'
import IconSaxClipboard from '@/components/Icons/iconsax/IconSaxClipboard.vue'
import IconSaxExportSquare from '@/components/Icons/iconsax/IconSaxExportSquare.vue'
import IconSaxMobile from '@/components/Icons/iconsax/IconSaxMobile.vue'
import DashboardNotificationFlyout from '@/areas/dashboard/layout/nav/DashboardNotificationFlyout.vue'
import IconSaxCalendarEdit from '@/components/Icons/iconsax/IconSaxCalendarEdit.vue'
import DashboardNavigationItem from '@/areas/dashboard/layout/nav/DashboardNavigationItem.vue'
import { upgradeDialog } from '@/helpers/upgradeDialog'
import GradientButton from '@/components-v2/data-display/GradientButton.vue'
import IconSaxAdd from '@/components/Icons/iconsax/IconSaxAdd.vue'
import SilverPlanButton from '@/components/Account/Upgrade/SilverPlanButton.vue'
import GoldPlanButton from '@/components/Account/Upgrade/GoldPlanButton.vue'
import DashboardNavigationBanner from '@/areas/dashboard/layout/nav/DashboardNavigationBanner.vue'
import IconSaxVideoVertical from '@/components/Icons/iconsax/IconSaxVideoVertical.vue'
import IconSaxGhost from '@/components/Icons/iconsax/IconSaxGhost.vue'
import IconSaxCloudConnection from '@/components/Icons/iconsax/IconSaxCloudConnection.vue'
import { Button } from '@/components/ui/button'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import { useShowDifferentDashboardLayout, useFeatureFlagVariantEnabled } from '@/Hooks/useFeatureFlagEnabled'
import { editorRouteNames } from '@/areas/editor/routeNames'
import { posthog } from 'posthog-js'

const userInfo = useUserInfoStore()

const isOpen = ref(false)
useRouter().afterEach(() => {
  isOpen.value = false
})

function openUpgradeDialog() {
  upgradeDialog.open({
    tier: 100,
    reason: 'Dashboard navigation banner',
    subtitle: undefined,
    title: undefined,
    intentional: true,
  })
}

const showDifferentDashboardLayout = useShowDifferentDashboardLayout()
const hasClipGPT = useFeatureFlagVariantEnabled('clip-gpt', 'clipgpt')
const route = useRoute()
const router = useRouter()

const isOpenCreateNew = ref(false)

function onButtonClicked(text: string, routeName: RouteRecordName | string) {
  posthog.capture('Dashboard Button Clicked', {
    button: text,
    route: typeof routeName === 'string' && routeName.startsWith('https://')
      ? routeName
      : router.resolve({ name: routeName }).href
  });
}
</script>

<template>
  <section
    v-click-outside="() => (isOpen = false)"
    :class="isOpen ? 'max-lg:min-h-[100dvh]' : 'max-lg:min-h-0'"
    class="flex flex-col overflow-hidden transition-all lg:h-full lg:overflow-y-auto"
  >
    <header class="flex items-center justify-between">
      <RouterLink
        :to="{ name: dashboardRouteNames.dashboard }"
        class="flex flex-nowrap items-center gap-4 overflow-x-hidden rounded-lg px-4 py-2 text-brand-state-text-primary lg:w-full"
      >
        <img alt="StreamLadder branding" class="h-8 w-8" height="192" src="/images/logo.png" width="192" />
        <span class="flex items-center gap-2">
          <span class="h-4 text-lg font-semibold leading-[1]">StreamLadder</span>
          <GoldPlanButton v-if="userInfo.tier === tiers.GOLD" class="pointer-events-none h-4 w-4" small />
          <SilverPlanButton v-else-if="userInfo.tier === tiers.SILVER" class="pointer-events-none h-4 w-4" small />
          <span v-else class="flex rounded bg-white px-1 py-0.5 text-xs font-semibold uppercase text-indigo-500">
            {{ userInfo.plan }}
          </span>
        </span>
      </RouterLink>

      <DashboardHamburgerButton v-model="isOpen" />
    </header>
    <section
      :class="isOpen ? 'max-lg:max-h-[150vh]' : 'max-lg:max-h-0'"
      class="flex flex-auto flex-col gap-4 transition-all lg:gap-6"
    >
      <nav class="flex flex-col gap-2 pt-6">

        <div v-if="showDifferentDashboardLayout" class="mt-auto ml-auto w-full">
          <Popover v-model:open="isOpenCreateNew">
            <PopoverTrigger as-child>
              <GradientButton
                class="w-full !justify-start gap-4 rounded-lg !px-4 !font-semibold tracking-wide transition-all hover:overflow-visible"
              >
                <IconSaxAdd class="shrink-0 [&>path]:stroke-2" />
                <span>Create new</span>
              </GradientButton>
            </PopoverTrigger>
            <PopoverContent
              align="start"
              class="mt-2 p-4 flex flex-col gap-2 layer-2 rounded-xl w-auto max-w-[100dvw]"
              side="bottom"
              trap-focus
              @click.stop="isOpenCreateNew = false"
            >
              <RouterLink :to="{ name: editorRouteNames.root }" @click="onButtonClicked('Vertical Clip', editorRouteNames.root)">
                <Button variant="ghost" class="w-full justify-start">
                  <IconSaxClipboard class="shrink-0" />
                  <span>Clip Editor</span>
                </Button>
              </RouterLink>
              <RouterLink :to="{ name: dashboardRouteNames.contentPublisher.post }" @click="onButtonClicked('Social Media Post', dashboardRouteNames.contentPublisher.post)">
                <Button variant="ghost" class="w-full justify-start">
                  <IconSaxCalendarEdit class="shrink-0" />
                  <span>Social Media Post</span>
                </Button>
              </RouterLink>
              <RouterLink :to="{ name: dashboardRouteNames.montageMaker }" @click="onButtonClicked('Clip Montage', dashboardRouteNames.montageMaker)">
                <Button variant="ghost" class="w-full justify-start">
                  <IconSaxVideoVertical class="shrink-0" />
                  <span class="flex w-full items-center justify-between gap-1">
                    Clip Montage
                  </span>
                </Button>
              </RouterLink>
              <RouterLink v-if="hasClipGPT" :to="{ name: dashboardRouteNames.clipGpt.root }"  @click="onButtonClicked('Clips from Stream', dashboardRouteNames.clipGpt.root)">
                <Button variant="ghost" class="w-full justify-start">
                  <IconSaxGhost class="shrink-0" />
                  <span class="flex w-full items-center justify-between gap-4">
                    Clips from Stream
                    <span class="rounded-full font-semibold text-xs uppercase px-3 py-1 leading-[1] bg-foreground text-background">
                      Beta
                    </span>
                  </span>
                </Button>
              </RouterLink>
              <Button variant="ghost" class="w-full justify-start" as="a" href="https://emotes.streamladder.com/" target="_blank" @click="onButtonClicked('Animated Emote', 'https://emotes.streamladder.com/')">
                <IconSaxHappyemoji class="shrink-0" />
                <span class="flex w-full items-center justify-between gap-3">
                    Animated Emote
                    <IconSaxExportSquare class="ml-auto h-4 w-4" />
                  </span>
              </Button>
            </PopoverContent>
          </Popover>
        </div>

        <RouterLink v-else :to="{ name: dashboardRouteNames.editor }" class="mb-4 rounded-lg" @click="onButtonClicked('Create clip', dashboardRouteNames.editor)">
          <GradientButton
            class="w-full !justify-start gap-4 rounded-lg !px-4 !font-semibold tracking-wide transition-all hover:overflow-visible"
          >
            <IconSaxAdd class="shrink-0 [&>path]:stroke-2" />
            <span>Create clip</span>
          </GradientButton>
        </RouterLink>

        <div class="flex flex-col gap-2 min-h-0">
          <DashboardNavigationItem :route="dashboardRouteNames.dashboard" @click="onButtonClicked('Dashboard', dashboardRouteNames.dashboard)">
            <IconSaxHomeTrendUp class="shrink-0" />
            <span v-if="showDifferentDashboardLayout">Start</span>
            <span v-else>Dashboard</span>
          </DashboardNavigationItem>

          <DashboardNavigationItem v-if="!showDifferentDashboardLayout" :route="dashboardRouteNames.editor" @click="onButtonClicked('Clip Editor', dashboardRouteNames.editor)">
            <IconSaxClipboard class="shrink-0" />
            <span>Clip Editor</span>
          </DashboardNavigationItem>

          <DashboardNavigationItem v-if="!showDifferentDashboardLayout" :route="dashboardRouteNames.montageMaker" @click="onButtonClicked('Montage Maker', dashboardRouteNames.montageMaker)">
            <IconSaxVideoVertical class="shrink-0" />
            <span class="flex w-full items-center justify-between gap-1">
            Montage Maker
          </span>
          </DashboardNavigationItem>

          <DashboardNavigationItem
            v-if="hasClipGPT && !showDifferentDashboardLayout"
            :route="dashboardRouteNames.clipGpt.root" path
          >
            <IconSaxGhost class="shrink-0" />
            <span class="flex w-full items-center justify-between gap-1">
              ClipGPT
              <span 
                class="rounded-full bg-white text-black font-semibold text-xs uppercase px-3 py-1 leading-[1]" 
                :class="route.fullPath.includes('/clip-gpt') ? 'text-indigo-600 group-hover:!text-indigo-700' : 'text-black'"
              >
                Beta
              </span>
            </span>
          </DashboardNavigationItem>

          <DashboardNavigationItem v-if="!showDifferentDashboardLayout" :route="dashboardRouteNames.downloader.root" @click="onButtonClicked('Clip Downloader', dashboardRouteNames.downloader.root)">
            <IconSaxCloudConnection class="shrink-0" />
            <span class="flex w-full items-center justify-between gap-1">
              Clip Downloader
              <span class="rounded-full bg-emerald-100 dark:bg-emerald-700 text-emerald-700 dark:text-emerald-100 px-2 py-0.5 text-xs text-uppercase">
                New
              </span>
            </span>
          </DashboardNavigationItem>

          <DashboardNavigationItem
            v-if="!showDifferentDashboardLayout"
            href="https://emotes.streamladder.com/"
            target="_blank"
            @click="onButtonClicked('Emote Maker', 'https://emotes.streamladder.com/')"
          >
            <IconSaxHappyemoji class="shrink-0" />
            <span class="flex w-full items-center justify-between gap-1">
              Emote Maker
              <IconSaxExportSquare class="ml-auto h-4 w-4" />
            </span>
          </DashboardNavigationItem>

          <DashboardNavigationItem :route="dashboardRouteNames.contentPublisher.root" @click="onButtonClicked('Content Publisher', dashboardRouteNames.contentPublisher.root)">
            <IconSaxCalendarEdit class="shrink-0" />
            <span>{{ showDifferentDashboardLayout ? 'Share & Schedule' : 'Content Publisher' }}</span>
          </DashboardNavigationItem>

          <DashboardNavigationItem v-if="!showDifferentDashboardLayout" :route="dashboardRouteNames.analytics" @click="onButtonClicked('Analytics', dashboardRouteNames.analytics)">
            <IconSaxDiagram class="shrink-0" />
            <span> Analytics </span>
          </DashboardNavigationItem>

          <DashboardNavigationItem :route="dashboardRouteNames.videos" @click="onButtonClicked('My Videos', dashboardRouteNames.videos)">
            <IconSaxMobile class="shrink-0" />
            <span>My Videos</span>
          </DashboardNavigationItem>
          <DashboardNavigationItem v-if="!showDifferentDashboardLayout" :route="dashboardRouteNames.socials" @click="onButtonClicked('Social Media Connections', dashboardRouteNames.socials)">
            <IconSaxShare class="shrink-0" />
            <span>Social Media Connections</span>
          </DashboardNavigationItem>
        </div>
      </nav>

      <nav class="relative mt-auto flex flex-col gap-2">
        <div class="flex flex-col gap-1 sm:flex-row lg:flex-col">
          <button v-if="userInfo.tier < tiers.GOLD" class="flex w-full overflow-hidden" @click="openUpgradeDialog">
            <DashboardNavigationBanner
              call-to-action="Upgrade now"
              class="bg-[#663DF0] text-sm"
              title="Get access to all features on StreamLadder"
            />
          </button>
        </div>

        <DashboardNotificationFlyout />
        <!--<DashboardNavigationItem :route="dashboardRouteNames.whatsNew">
          <IconSaxInformation class="shrink-0" />
          <span>What’s new?</span>
        </DashboardNavigationItem>-->
        <DashboardNavigationItem v-if="showDifferentDashboardLayout" :route="dashboardRouteNames.socials" @click="onButtonClicked('Social Media Connections', dashboardRouteNames.socials)">
          <IconSaxShare class="shrink-0" />
          <span>Social Media Connections</span>
        </DashboardNavigationItem>
      </nav>

      <DashboardAccountInfoFlyout />
    </section>
  </section>
</template>

<style lang="scss" scoped></style>
