<script setup lang="ts">
import { useEditorMainStore } from '@/store/editor/editorMain'
import { useEditorFocusStore } from '@/store/editor/editorFocus'
import { useVideoStore } from '@/areas/editor/store/useVideoStore'
import { onKeyStroke } from '@vueuse/core'
import { interpolate, isInputTarget, msToTime } from '@/areas/editor/timeline/helpers'
import { useTimelineStore } from '@/modules/SLTimeline/useTimelineStore'
import IconSaxAddCircle from '@/components/Icons/iconsax/IconSaxAddCircle.vue'
import TimelineZoomSlider from '@/areas/editor/timeline/header/TimelineZoomSlider.vue'
import IconSaxMinusCirlce from '@/components/Icons/iconsax/IconSaxMinusCirlce.vue'
import IconCustomMaximize from '@/components/Icons/Normalized/IconCustomMaximize.vue'
import IconSaxPause from '@/components/Icons/iconsax/IconSaxPause.vue'
import IconSaxPlay from '@/components/Icons/iconsax/IconSaxPlay.vue'
import IconSaxTrash from '@/components/Icons/iconsax/IconSaxTrash.vue'
import IconSaxScissor from '@/components/Icons/iconsax/IconSaxScissor.vue'
import { computed } from 'vue'
import IconSaxStatus from '@/components/Icons/iconsax/IconSaxStatus.vue'
import IconSaxArrowLeftStop from '@/components/Icons/iconsax/IconSaxArrowLeftStop.vue'
import IconSaxArrowRightStop from '@/components/Icons/iconsax/IconSaxArrowRightStop.vue'
import { Button } from '@/components/ui/button'
import { Tooltip, TooltipTrigger, TooltipContent } from '@/components/ui/tooltip'
import TimelineAiSplitButton from '@/areas/editor/timeline/header/TimelineAiSplitButton.vue'
import TimelineVolumeButton from '@/areas/editor/timeline/header/TimelineVolumeButton.vue'
import IconSaxSidebarBottom from '@/components/Icons/iconsax/IconSaxSidebarBottom.vue'
import { useSegmentsStore } from '@/areas/editor/store/useSegmentsStore'
import { minBy, maxBy } from 'lodash-es'

const editorMainStore = useEditorMainStore()
const editorFocusStore = useEditorFocusStore()
const videoStore = useVideoStore()

const { zoomToLevel, zoomLevel, fitToTimeline, _autoScroll, open } = useTimelineStore()

const togglePlaying = () => {
  _autoScroll.value = 'idle'
  videoStore.playing = !videoStore.playing
  videoStore.preservedPaused = !videoStore.preservedPaused
}

const splitSegment = () => {
  editorFocusStore.splitFocusModel(videoStore.getExactTime())
}

const pullLeft = () => {
  editorFocusStore.pullModel(videoStore.getExactTime(), 'left')
}

const pullRight = () => {
  editorFocusStore.pullModel(videoStore.getExactTime(), 'right')
}

onKeyStroke(['['], (e) => {
  // if event is not triggered on a html content editable or text input prevent
  if (isInputTarget(e)) return
  e.preventDefault()
  pullLeft()
})

onKeyStroke([']'], (e) => {
  // if event is not triggered on a html content editable or text input prevent
  if (isInputTarget(e)) return
  e.preventDefault()
  pullRight()
})

const nudgeZoom = (_nudgeAmount: number) => {
  const zoomedLevel = Math.min(Math.max(zoomLevel.value + _nudgeAmount, 0), 1)
  zoomToLevel(zoomedLevel, videoStore.getExactTime())
}

const nudgeAmount = computed(() => {
  const minStep = 5 / 100
  const minDuration = 10000
  const maxStep = 20 / 100
  const maxDuration = 60000
  const duration = videoStore.duration
  const step = Math.max(0, Math.min(1, duration - minDuration / maxDuration - minDuration))
  return interpolate(minStep, maxStep, step)
})

const segmentsStore = useSegmentsStore()
const segments = segmentsStore.whereIsNotZoom()
function fitCrop() {
  fitToTimeline(
    Math.min(...segments.value.map(s => s.startMs)),
    Math.max(...segments.value.map(s => s.endMs)))
}
</script>

<template>
  <div class="flex items-center justify-between px-0.5 py-2 md:p-2">
    <div class="hidden flex-row flex-wrap items-center gap-1 md:flex md:basis-4/6">
      <TimelineAiSplitButton v-if="open && false" />
      <Tooltip v-if="open">
        <TooltipTrigger>
          <Button @click="splitSegment" variant="ghost" size="sm">
            <IconSaxScissor class="w-4 h-4" />
            <span class="hidden md:inline">Split</span>
          </Button>
        </TooltipTrigger>
        <TooltipContent>
          <span>Split clip at current time (s)</span>
        </TooltipContent>
      </Tooltip>
      <Tooltip v-if="open">
        <TooltipTrigger>
          <Button @click="pullRight" variant="ghost" size="sm" class="h-auto p-2">
            <IconSaxArrowRightStop class="h-4 w-4" />
          </Button>
        </TooltipTrigger>
        <TooltipContent>
          <span>Trim to the left</span>
        </TooltipContent>
      </Tooltip>
      <Tooltip v-if="open">
        <TooltipTrigger>
          <Button @click="pullLeft" variant="ghost" size="sm" class="h-auto p-2">
            <IconSaxArrowLeftStop class="h-4 w-4" />
          </Button>
        </TooltipTrigger>
        <TooltipContent>
          <span>Trim to the right</span>
        </TooltipContent>
      </Tooltip>
      <Tooltip v-if="editorFocusStore.canDeleteFocusModel">
        <TooltipTrigger>
          <Button @click="editorFocusStore.deleteFocusModel" variant="ghostDestructive" size="sm" class="h-auto p-2">
            <IconSaxTrash class="h-4 w-4" />
          </Button>
        </TooltipTrigger>
        <TooltipContent>
          <span>Delete selected</span>
        </TooltipContent>
      </Tooltip>
    </div>

    <div class="flex h-8 flex-row items-center justify-center gap-0.5 leading-none md:basis-1/3 md:gap-2">
      <!--      <button @click="nudge(-nudgeAmount)" class="flex h-6 w-6 text-gray-300">-->
      <!--        <Backward />-->
      <!--      </button>-->
      <!--      <button @click="nudge(nudgeAmount)" class="flex h-6 w-6 text-gray-300">-->
      <!--        <Forward />-->
      <!--      </button>-->
      <Tooltip>
        <TooltipTrigger>
          <Button variant="ghost" size="sm" class="p-0 w-8" @click="togglePlaying">
            <IconSaxStatus class="h-4 w-4 animate-spin" v-if="videoStore.waiting" />
            <IconSaxPlay class="h-4 w-4 fill-current" v-else-if="!videoStore.playing" />
            <IconSaxPause type="bold" class="h-4 w-4" v-else />
          </Button>
        </TooltipTrigger>
        <TooltipContent>
          <span v-if="!videoStore.playing">Play (space)</span>
          <span v-else>Pause (space)</span>
        </TooltipContent>
      </Tooltip>

      <Tooltip>
        <TooltipTrigger class="flex gap-0.5 md:gap-2">
          <span class="min-w-[5ch] font-semibold opacity-90">
            {{ msToTime(videoStore.currentTimeMs) }}
          </span>
          <span class="hidden opacity-50 md:inline">/</span>
          <span class="hidden min-w-[5ch] font-normal text-gray-500 min-[400px]:inline">
            {{ msToTime(videoStore._duration * 1000) }}
          </span>
        </TooltipTrigger>
        <TooltipContent>
          <span>Duration of exported video: </span>
          <span class="font-semibold"> {{ msToTime(videoStore.duration) }}</span>
        </TooltipContent>
      </Tooltip>
    </div>

    <div class="audio relative flex flex-row flex-wrap items-center gap-0.5 md:gap-1 justify-end md:basis-4/6">
      <TimelineVolumeButton />
      <Tooltip v-if="open">
        <TooltipTrigger>
          <Button
            @click="fitCrop"
            variant="ghost"
            size="sm"
          >
            <IconCustomMaximize class="w-4 h-4" />
            <span class="hidden md:inline font-medium">Fit</span>
          </Button>
        </TooltipTrigger>
        <TooltipContent>
          <span>Zoom to fit clip to timeline</span>
        </TooltipContent>
      </Tooltip>

      <div class="relative flex items-center gap-1 md:basis-auto" v-if="open">
        <Tooltip>
          <TooltipTrigger>
            <Button
              variant="ghost"
              size="sm"
              class="h-auto p-2 disabled:bg-transparent"
              :disabled="zoomLevel === 1"
              @click="nudgeZoom(nudgeAmount)"
            >
              <IconSaxMinusCirlce class="h-4 w-4" />
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            <span>Zoom out</span>
          </TooltipContent>
        </Tooltip>
        <TimelineZoomSlider />
        <Tooltip>
          <TooltipTrigger>
            <Button
              variant="ghost"
              size="sm"
              class="h-auto p-2 disabled:bg-transparent"
              :disabled="zoomLevel === 0"
              @click="nudgeZoom(nudgeAmount * -1)"
            >
              <IconSaxAddCircle class="h-4 w-4" />
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            <span>Zoom in</span>
          </TooltipContent>
        </Tooltip>
      </div>
      <Tooltip>
        <TooltipTrigger>
          <Button
            variant="ghost"
            size="sm"
            class="h-auto p-2 disabled:bg-transparent"
            @click="open = !open"
          >
            <IconSaxSidebarBottom class="h-4 w-4" :class="{ 'rotate-180': !open }" />
          </Button>
        </TooltipTrigger>
        <TooltipContent>
          <span v-if="open">Hide timeline</span>
          <span v-else>Show timeline</span>
        </TooltipContent>
      </Tooltip>
    </div>
  </div>
</template>

<style scoped>
.btn.btn-ghost {
  background-color: transparent;
  border-color: transparent;
  padding-inline: 4px;
  gap: 0.25rem;
  display: flex;
  font-weight: 400;
}

.btn.btn-ghost.btn-info {
  background-color: #f2fcfe;
  color: #3b82f6;
}

.btn.btn-ghost.btn-success {
  background-color: #f0fdf4;
  color: #097c55;
}

.btn.btn-ghost:hover {
  background: #f3f4f6;
  border-color: transparent;
  color: black;
}

#zoomSlider {
  direction: rtl;
}

button > svg {
  max-width: 100%;
  max-height: 100%;
  margin: 0 auto;
}
</style>
